import { GenericAbortSignal } from 'axios';
import { Connection } from './connection';
import { Video } from './video';
import { UserBank, UserBankType } from './user_bank';
import { Transaction } from './transaction';

export class User {
  id: number;
  nickname: string;
  email: string;
  name: string;
  profilePath: string | null;
  bannerPath: string | null;
  socials: string[];
  bio: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    id: number,
    nickname: string,
    email: string,
    name: string,
    profilePath: string | null,
    bannerPath: string | null,
    socials: string[],
    bio: string,
    createdAt: Date,
    updatedAt: Date
  ) {
    this.id = id;
    this.nickname = nickname;
    this.email = email;
    this.name = name;
    this.profilePath = profilePath;
    this.bannerPath = bannerPath;
    this.socials = socials;
    this.bio = bio;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static fromData(data: { [key: string]: any }): User {
    return new User(
      data['id'] as number,
      data['nickname'] as string,
      data['email'] as string,
      data['name'] as string,
      data['profilePath'] as string | null,
      data['bannerPath'] as string | null,
      data['socials'] as string[],
      data['bio'] as string,
      new Date(data['createdAt']),
      new Date(data['updatedAt'])
    );
  }

  static async me(signal?: GenericAbortSignal): Promise<User> {
    const data = await Connection.instance.get('/me', signal);
    return User.fromData(data['user']);
  }

  async update(nickname: string, name: string, socials: string[], bio: string): Promise<User> {
    const data = await Connection.instance.put(`/users/${this.id}`, { nickname, name, socials, bio });
    return User.fromData(data['user']);
  }

  async updatePassword(oldPassword: string, newPassword: string): Promise<User> {
    const data = await Connection.instance.put(`/users/${this.id}/password`, { oldPassword, newPassword });
    return User.fromData(data['user']);
  }

  async uploadProfile(contentType: string, extension: string): Promise<{ upload: string; url: string }> {
    const data = await Connection.instance.post(`/users/${this.id}/profile`, { contentType, extension });
    return { upload: data['upload'], url: data['url'] };
  }

  async uploadBanner(contentType: string, extension: string): Promise<{ upload: string; url: string }> {
    const data = await Connection.instance.post(`/users/${this.id}/banner`, { contentType, extension });
    return { upload: data['upload'], url: data['url'] };
  }

  async bank(signal?: GenericAbortSignal): Promise<UserBank | null> {
    const data = await Connection.instance.get(`/users/${this.id}/bank`, signal);
    if (data['bank'] === null) return null;
    return UserBank.fromData(data['bank']);
  }

  async updateBank(
    name: string,
    type: UserBankType,
    bankName: string,
    bankAddress: string,
    accountNumber: string,
    routingNumber: string | null,
    swiftCode: string | null,
    state: string | null,
    city: string | null,
    address: string | null,
    postalCode: string | null
  ): Promise<UserBank | null> {
    const data = await Connection.instance.put(`/users/${this.id}/bank`, {
      name,
      type,
      bankName,
      bankAddress,
      accountNumber,
      routingNumber,
      swiftCode,
      state,
      city,
      address,
      postalCode,
    });
    return UserBank.fromData(data['bank']);
  }

  async transactions(signal?: GenericAbortSignal): Promise<Transaction[]> {
    const data = await Connection.instance.get(`/users/${this.id}/transactions`, signal);
    return (data['transactions'] as any[]).map((video) => Transaction.fromData(video));
  }

  async videos(signal?: GenericAbortSignal): Promise<Video[]> {
    const data = await Connection.instance.get(`/users/${this.id}/videos`, signal);
    return (data['videos'] as any[]).map((video) => Video.fromData(video));
  }

  async addVideo(title: string): Promise<Video> {
    const data = await Connection.instance.post(`/users/${this.id}/videos`, { title });
    return Video.fromData(data['video']);
  }

  async earnings(signal?: GenericAbortSignal): Promise<{ key: Date; value: number }[]> {
    const data = await Connection.instance.get(`/users/${this.id}/analytics/earnings`, signal);
    return (data['earnings'] as { key: string; value: number }[]).map(({ key, value }) => ({ key: new Date(key), value: value }));
  }

  async views(signal?: GenericAbortSignal): Promise<{ key: Date; value: number }[]> {
    const data = await Connection.instance.get(`/users/${this.id}/analytics/views`, signal);
    return (data['views'] as { key: string; value: number }[]).map(({ key, value }) => ({ key: new Date(key), value: value }));
  }

  async regions(signal?: GenericAbortSignal): Promise<{ key: string; value: number }[]> {
    const data = await Connection.instance.get(`/users/${this.id}/analytics/regions`, signal);
    return data['regions'] as { key: string; value: number }[];
  }

  async ages(signal?: GenericAbortSignal): Promise<{ key: string; value: number }[]> {
    const data = await Connection.instance.get(`/users/${this.id}/analytics/ages`, signal);
    return data['ages'] as { key: string; value: number }[];
  }

  async genders(signal?: GenericAbortSignal): Promise<{ key: string; value: number }[]> {
    const data = await Connection.instance.get(`/users/${this.id}/analytics/genders`, signal);
    return data['genders'] as { key: string; value: number }[];
  }
}
