import axios, { Axios, GenericAbortSignal, RawAxiosRequestHeaders } from 'axios';
import env from '../env';

export class Connection {
  private static _instance: Connection | null = null;
  static get instance(): Connection {
    if (this._instance === null) {
      this._instance = new Connection();
    }

    return this._instance;
  }

  private _headers: RawAxiosRequestHeaders = { 'Content-Type': 'application/json; charset=UTF-8' };
  private _axios: Axios;

  constructor() {
    this._axios = axios.create({ baseURL: env.REACT_APP_API_ENDPOINT });
    this._axios.defaults.withCredentials = true;

    this._axios.interceptors.request.use((req) => {
      Object.entries(this._headers).forEach(([key, value]) => {
        req.headers.set(key, value);
      });

      return req;
    });
  }

  setHeaders(headers: RawAxiosRequestHeaders) {
    this._headers = { ...this._headers, ...headers };
  }

  async get(path: string, signal?: GenericAbortSignal): Promise<any> {
    const res = await this._axios.get(path, { signal: signal });
    return res.data;
  }

  async post(path: string, body?: { [key: string]: any }): Promise<any> {
    const res = await this._axios.post(path, body);
    return res.data;
  }

  async put(path: string, body?: { [key: string]: any }): Promise<any> {
    const res = await this._axios.put(path, body);
    return res.data;
  }

  async delete(path: string): Promise<any> {
    const res = await this._axios.delete(path);
    return res.data;
  }
}
