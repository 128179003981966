import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Colors } from '../../styles';

const SidebarButton: FC<{ icon: ReactNode; title: string; route: string }> = ({ icon, title, route }) => {
  const navigate = useNavigate();
  const location = useLocation();

  function handleClick() {
    if (location.pathname === route) {
      return;
    }

    navigate(route);
  }

  return (
    <ListItem
      sx={{
        padding: 0,
        boxSizing: 'border-box',
      }}
    >
      <ListItemButton
        aria-label={title}
        onClick={handleClick}
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          pt: 2,
          px: 1,
          my: 0.3,
          borderRadius: '12px',
          width: '100%',
          backgroundColor: location.pathname.startsWith(route) ? Colors.lightGrey : 'white',
          '& .MuiListItemIcon-root, & .MuiListItemText-root': {
            color: 'black',
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            '& svg': {
              fontSize: '24px',
            },
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} primaryTypographyProps={{ fontSize: '12px' }} sx={{ textAlign: 'center' }} />
      </ListItemButton>
    </ListItem>
  );
};

export default SidebarButton;
