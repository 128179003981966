import { ChangeEvent, FormEvent, useState } from 'react';
import Logo1024 from '../assets/logo_1024.png';
import { AxiosError } from 'axios';
import { Alert, Box, Button, Container, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { EmailOutlined, LockOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../styles';
import Copyright from '../components/Copyright';
import { useAuth } from '../stores';

export default function SignInPage() {
  const { signin } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isSigning, setIsSigning] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  function handleChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
    setError(null);
  }

  function handleChangePassword(e: ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
    setError(null);
  }

  function handleResetPassword() {
    navigate('/forgot');
  }

  async function handleSubmit(e: FormEvent<HTMLButtonElement>) {
    try {
      e.preventDefault();

      if (email === '' || password === '') {
        setError('Invalid email or password.');
        return;
      }

      setIsSigning(true);
      await signin(email, password);
      navigate('/');
    } catch (e) {
      console.error(e);
      if (e instanceof AxiosError) {
        if (e.response?.status === 401) {
          setError('Invalid Email or Password.');
        } else {
          setError('Server Error.');
        }
        console.info(e.response?.data);
      }
    } finally {
      setIsSigning(false);
    }
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={Logo1024}
          alt="logo"
          style={{
            width: '60%',
            minWidth: '206px',
            minHeight: '65px',
            marginBottom: '10px',
          }}
        />
        <Box component="form" noValidate sx={{ width: '85%' }}>
          {error && (
            <Alert severity="error" sx={{ mb: '10px' }}>
              {error}
            </Alert>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleChangeEmail}
            onClick={() => setError(null)}
            value={email}
            disabled={isSigning}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChangePassword}
            onClick={() => setError(null)}
            value={password}
            disabled={isSigning}
            sx={{ mb: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              disableRipple
              variant="text"
              color="primary"
              onClick={handleResetPassword}
              sx={{
                color: Colors.secondary,
                fontSize: '10px',
                textTransform: 'none',
                fontWeight: 500,
                py: 0,
                px: 0,
                '&:hover': { backgroundColor: 'white' },
              }}
            >
              Forgot password?
            </Button>
          </Box>
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }} onClick={handleSubmit} disabled={isSigning}>
            SIGN IN
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Typography variant="body2">
              Want to become a SmthFlex creator?{' '}
              <Link href="/signup" variant="body2" color={Colors.primary} sx={{ fontWeight: 600 }}>
                Sign Up!
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Copyright />
    </Container>
  );
}
