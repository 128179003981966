import { LockOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { FC, MouseEventHandler, useState } from 'react';
import { Colors } from '../../styles';
import { User } from '../../models';
import { AxiosError } from 'axios';

const PasswordModal: FC<{
  open: boolean;
  user: User;
  onClose: MouseEventHandler;
}> = ({ open, user, onClose }) => {
  const [password, setPassword] = useState<{
    currentPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
    error: string | null;
  }>({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    error: null,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  function handleChangePassword(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: 'currentPassword' | 'newPassword' | 'newPasswordConfirm'
  ) {
    setPassword((prev) => ({ ...prev, error: null, [key]: e.target.value }));
  }

  async function handleSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    try {
      if (password.currentPassword === '' || password.newPassword === '' || password.newPasswordConfirm === '') {
        setPassword((prev) => ({ ...prev, error: 'Invalid password.' }));
        return;
      }

      if (!/^(?=.*\d)(?=.*[!@#$%^&*()_+=\-{}[\]:";'<>?,./~`|\\]).{8,}$/.test(password.newPassword)) {
        setPassword((prev) => ({ ...prev, error: 'Check the password policy.' }));
        return;
      }

      if (password.newPassword !== password.newPasswordConfirm) {
        setPassword((prev) => ({ ...prev, error: "New Passwords don't match." }));
        return;
      }

      setIsSubmitting(true);

      await user.updatePassword(password.currentPassword, password.newPassword);
      handleClose(e);
    } catch (e) {
      console.error(e);
      if (e instanceof AxiosError) {
        if (e.response?.status === 412) {
          setPassword((prev) => ({ ...prev, error: 'Invalid Current Password.' }));
        }

        console.info(e.response?.data);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleClose(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setPassword({ currentPassword: '', newPassword: '', newPasswordConfirm: '', error: null });
    setIsSubmitting(false);
    onClose(e);
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" sx={{ '& .MuiDialog-paper': { borderRadius: '20px' } }}>
      <DialogTitle
        sx={{
          pt: '16px',
          pb: '12px',
          px: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Change Password</Typography>
      </DialogTitle>
      <DialogContent>
        {password.error && (
          <Alert severity="error" sx={{ px: '16px', mb: '10px' }}>
            {password.error}
          </Alert>
        )}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="current-password"
          label="Current Password"
          type={showPassword ? 'text' : 'password'}
          id="current-password"
          onChange={(e) => handleChangePassword(e, 'currentPassword')}
          value={password.currentPassword}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                  <VisibilityOutlined sx={{ color: showPassword ? Colors.secondary : 'grey' }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="new-password"
          label="New Password"
          type={showPassword ? 'text' : 'password'}
          id="new-password"
          onChange={(e) => handleChangePassword(e, 'newPassword')}
          value={password.newPassword}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                  <VisibilityOutlined sx={{ color: showPassword ? Colors.secondary : 'grey' }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="new-password-confirm"
          label="New Password Confirm"
          type={showPassword ? 'text' : 'password'}
          id="new-password-confirm"
          onChange={(e) => handleChangePassword(e, 'newPasswordConfirm')}
          value={password.newPasswordConfirm}
          helperText={'At least 1 number, 1 special character, and be 8+ characters long.'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlined />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                  <VisibilityOutlined sx={{ color: showPassword ? Colors.secondary : 'grey' }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions sx={{ px: '20px', pt: '12px', pb: '18px', justifyContent: 'end' }}>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            borderRadius: '20px',
            pt: '8px',
            pb: '6px',
            px: '18px',
            mr: '4px',
            color: 'black',
            backgroundColor: Colors.lightGrey,
            ':hover': {
              backgroundColor: 'grey',
            },
          }}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting}
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            borderRadius: '20px',
            pt: '8px',
            pb: '6px',
            px: '18px',
            backgroundColor: 'black',
            ':hover': {
              backgroundColor: 'grey',
            },
          }}
        >
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordModal;
