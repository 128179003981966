export type UserBankType = 'INDIVIDUAL' | 'COMPANY';

export class UserBank {
  id: number;
  name: string;
  type: UserBankType;
  bankName: string;
  bankAddress: string;
  accountNumber: string;
  routingNumber: string | null;
  swiftCode: string | null;
  state: string | null;
  city: string | null;
  address: string | null;
  postalCode: string | null;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    id: number,
    name: string,
    type: UserBankType,
    bankName: string,
    bankAddress: string,
    accountNumber: string,
    routingNumber: string | null,
    swiftCode: string | null,
    state: string | null,
    city: string | null,
    address: string | null,
    postalCode: string | null,
    createdAt: Date,
    updatedAt: Date
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.bankName = bankName;
    this.bankAddress = bankAddress;
    this.accountNumber = accountNumber;
    this.routingNumber = routingNumber;
    this.swiftCode = swiftCode;
    this.state = state;
    this.city = city;
    this.address = address;
    this.postalCode = postalCode;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static fromData(data: { [key: string]: any }): UserBank {
    return new UserBank(
      data['id'] as number,
      data['name'] as string,
      data['type'] as UserBankType,
      data['bankName'] as string,
      data['bankAddress'] as string,
      data['accountNumber'] as string,
      data['routingNumber'] as string | null,
      data['swiftCode'] as string | null,
      data['state'] as string | null,
      data['city'] as string | null,
      data['address'] as string | null,
      data['postalCode'] as string | null,
      new Date(data['createdAt']),
      new Date(data['updatedAt'])
    );
  }
}
