import { createContext, FC, ReactNode, useContext, useState } from 'react';
import { Video } from '../models';
import axios, { AxiosProgressEvent } from 'axios';

interface Uploader {
  video: Video;
  url: string;
  progress: number;
}

type VideoModalType = 'UPLOAD' | 'EDIT' | 'SUBMIT' | 'DELETE' | null;

interface VideoContextInterface {
  modal: VideoModalType;
  handleModal: (value: VideoModalType, target?: Video | null) => void;
  video: Video | null;
  uploaders: Uploader[];
  upload: (video: Video, file: File) => void;
}

const VideoContext = createContext<VideoContextInterface | null>(null);

export const useVideo = (): VideoContextInterface => {
  const context = useContext(VideoContext);
  if (!context) {
    throw new Error('useVideo must be used within a VideoProvider');
  }
  return context;
};

export const VideoProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [modal, setModal] = useState<VideoModalType>(null);
  const [video, setVideo] = useState<Video | null>(null);
  const [uploaders, setUploaders] = useState<Uploader[]>([]);

  function handleModal(type: VideoModalType, target?: Video | null) {
    if (type === modal) {
      return;
    }

    setModal(type);
    setVideo(target ?? null);
  }

  async function upload(video: Video, file: File) {
    let extension = 'mp4';
    if (file.type.includes('quicktime')) {
      extension = 'mov';
    }

    const MB = 1024 * 1024;

    /**
     * 2GB 이하의 파일은 한 번에 업로드합니다.
     */
    if (file.size <= 2048 * MB) {
      const { upload, url } = await video.uploadVideo(file.type, extension);

      setUploaders((state) => [...state, { video, url, progress: 0 }]);

      await axios.put(upload, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (event: AxiosProgressEvent) => {
          setUploaders((state) => {
            return state.map((uploader) => {
              if (uploader.video.id !== video.id) {
                return uploader;
              }

              let progress = uploader.progress;
              if (event.progress !== undefined) {
                progress = event.progress * 100;
              } else {
                progress = (event.loaded * 100) / file.size;
              }

              return {
                ...uploader,
                progress,
              };
            });
          });
        },
      });

      return;
    }

    const CHUNK_SIZE = 128 * 1024 * 1024; // 128MB
    const chunks: Blob[] = [];

    for (let i = 0; i < Math.ceil(file.size / CHUNK_SIZE); i++) {
      const start = i * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, file.size);
      const chunk = file.slice(start, end);
      chunks.push(chunk);
    }

    const { uploads, key, uploadId, url } = await video.startUploadVideo(file.type, extension, chunks.length);
    setUploaders((state) => [...state, { video, url, progress: 0 }]);

    const parts = await Promise.all(
      uploads.map(async (upload) => {
        const res = await axios.put(upload.url, chunks[upload.partNumber - 1], {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: (event: AxiosProgressEvent) => {
            setUploaders((state) => {
              return state.map((uploader) => {
                if (uploader.video.id !== video.id) {
                  return uploader;
                }

                const progress = (event.bytes * 100) / file.size;

                return {
                  ...uploader,
                  progress: uploader.progress + progress,
                };
              });
            });
          },
        });

        return {
          etag: res.headers['etag'],
          partNumber: upload.partNumber,
        };
      })
    );

    await video.completeUploadVideo(parts, uploadId, key);
  }

  return <VideoContext.Provider value={{ modal, handleModal, video, uploaders, upload }}>{children}</VideoContext.Provider>;
};
