import { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Video } from '../../models';
import { useVideo } from '../../stores';
import MP4Player from './MP4Player';
import HLSPlayer from './HLSPlayer';

interface PlayerProps {
  video: Video;
  width?: number | string;
  height?: number | string;
  configs?: { [key: string]: any };
  [key: string]: any;
}

const VideoPlayer: FC<PlayerProps> = ({ video, width = '100%', height = '100%' }) => {
  const { uploaders } = useVideo();
  const uploader = uploaders.find((uploader) => uploader.video.id === video.id) ?? null;
  const [originalPath, setOriginalPath] = useState<string | null>(video.originalPath);

  useEffect(() => {
    if (originalPath === null && uploader !== null && uploader.progress >= 100) {
      setOriginalPath(uploader.url);
    }
    return () => {};
  }, [originalPath, uploader]);

  if (uploader !== null && uploader.progress < 100) {
    return (
      <Box
        sx={{
          width,
          minWidth: width,
          height,
          minHeight: height,
          backgroundColor: '#f4f4f4',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography component="div" sx={{ color: 'black', fontSize: '18px' }}>
          {`${uploader.progress.toFixed(1)}%`}
        </Typography>
      </Box>
    );
  }

  if (video.streamingPath !== null) {
    return (
      <Box
        sx={{
          width,
          minWidth: width,
          height,
          minHeight: height,
        }}
      >
        <HLSPlayer src={video.streamingPath} thumbnail={video.thumbnailPath} />
      </Box>
    );
  }

  if (originalPath !== null) {
    return (
      <Box
        sx={{
          width,
          minWidth: width,
          height,
          minHeight: height,
        }}
      >
        <MP4Player src={originalPath} thumbnail={video.thumbnailPath} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width,
        minWidth: width,
        height,
        minHeight: height,
        backgroundColor: '#f4f4f4',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress variant="indeterminate" size={24} thickness={3} />
    </Box>
  );
};

export default VideoPlayer;
