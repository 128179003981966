import { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Logo1024 from '../assets/logo_1024.png';
import { Auth, Connection } from '../models';
import { AxiosError } from 'axios';
import { query } from '../hooks';
import { AlternateEmailOutlined, EmailOutlined, LockOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Container, Box, TextField, InputAdornment, Button, Typography, Link, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import { Colors } from '../styles';
import Copyright from '../components/Copyright';
import env from '../env';

export default function SignUpPage() {
  const navigate = useNavigate();
  const [isSigning, setIsSigning] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const nicknameParam = searchParams.get('nickname');
  const [nickname, setNickname] = useState<{ value: string; error: boolean; helperText: string | null }>({
    value: nicknameParam ?? '',
    error: false,
    helperText: null,
  });
  const [email, setEmail] = useState<{ value: string; error: boolean; helperText: string | null }>({
    value: '',
    error: false,
    helperText: null,
  });
  const [password, setPassword] = useState<{ value: string; error: boolean }>({ value: '', error: false });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  function handleKeyDownNickname(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
    }
  }

  function handleChangeNickname(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value.length > 30) {
      return;
    }

    setNickname({ value: e.target.value.toLowerCase(), error: false, helperText: null });
  }

  function handleChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    setEmail({ value: e.target.value, error: false, helperText: null });
  }

  function handleChangePassword(e: ChangeEvent<HTMLInputElement>) {
    setPassword({ value: e.target.value, error: false });
  }

  function handleCheck(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    setIsChecked(checked);
  }

  async function handleSubmit(e: FormEvent<HTMLButtonElement>) {
    try {
      e.preventDefault();

      if (!/^[a-z0-9._]{1,30}$/.test(nickname.value)) {
        setNickname((prev) => {
          return { ...prev, error: true, helperText: null };
        });
        return;
      }

      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email.value)) {
        setEmail((prev) => {
          return { ...prev, error: true, helperText: 'Invalid email format.' };
        });
        return;
      }

      if (!/^(?=.*\d)(?=.*[!@#$%^&*()_+=\-{}[\]:";'<>?,./~`|\\]).{8,}$/.test(password.value)) {
        setPassword((prev) => {
          return { ...prev, error: true };
        });
        return;
      }

      if (nickname.error || email.error || password.error) {
        return;
      }

      setIsSigning(true);

      const result = await Auth.signUp(nickname.value, email.value, password.value);
      query.setQueryData(['user'], result.user);
      localStorage.setItem('access_token', result.accessToken);
      localStorage.setItem('refresh_token', result.refreshToken);
      Connection.instance.setHeaders({ Authorization: `Bearer ${result.accessToken}` });
      navigate('/');
    } catch (e) {
      console.error(e);
      if (e instanceof AxiosError) {
        if (e.response?.status === 409) {
          if (e.response?.data.code === 'CONFLICT_NICKNAME') {
            setNickname((prev) => {
              return { ...prev, error: true, helperText: 'This nickname is already in use.' };
            });
          } else if (e.response?.data.code === 'CONFLICT_EMAIL') {
            setEmail((prev) => {
              return { ...prev, error: true, helperText: 'This email is already in use.' };
            });
          }
        }

        console.info(e.response?.data);
      }
    } finally {
      setIsSigning(false);
    }
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={Logo1024}
          alt="logo"
          style={{
            width: '60%',
            minWidth: '206px',
            minHeight: '65px',
            marginBottom: '10px',
          }}
        />
        <Box component="form" noValidate sx={{ width: '85%' }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="nickname"
            label="Nickname"
            name="nickname"
            autoFocus={nicknameParam === null}
            placeholder="your_unique_nickname"
            onChange={handleChangeNickname}
            onKeyDown={handleKeyDownNickname}
            value={nickname.value}
            disabled={nicknameParam !== null || isSigning}
            error={nickname.error}
            helperText={nickname.helperText ?? 'Maximum 30 characters; lowercase letters, numbers, periods, and underscores only.'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailOutlined />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            placeholder="smth@flex.com"
            autoFocus={nicknameParam !== null}
            onChange={handleChangeEmail}
            value={email.value}
            disabled={isSigning}
            error={email.error}
            helperText={email.helperText ?? ' Your email address will not be viewable by the public.'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            onChange={handleChangePassword}
            value={password.value}
            disabled={isSigning}
            error={password.error}
            helperText={'At least 1 number, 1 special character, and be 8+ characters long.'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                    <VisibilityOutlined sx={{ color: showPassword ? Colors.secondary : 'grey' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheck}
                sx={{
                  mr: '2px',
                  '&.Mui-checked:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              />
            }
            label={
              <Typography variant="body2">
                I accept the{' '}
                <Link
                  href={`${env.REACT_APP_HOST}/termsofservice.html`}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="body2"
                  color={Colors.secondary}
                  sx={{ fontWeight: 600 }}
                >
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link
                  href={`${env.REACT_APP_HOST}/privacy.html`}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="body2"
                  color={Colors.secondary}
                  sx={{ fontWeight: 600 }}
                >
                  Privacy Policy
                </Link>
              </Typography>
            }
            componentsProps={{ typography: { fontSize: '14px' } }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 1.5, mb: 2, backgroundColor: Colors.secondary }}
            onClick={handleSubmit}
            disabled={isSigning || !isChecked}
          >
            SIGN UP
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="body2">
              Already have an account?{' '}
              <Link href="/signin" variant="body2" color={Colors.primary} sx={{ fontWeight: 600 }}>
                Sign In!
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Copyright />
    </Container>
  );
}
