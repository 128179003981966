export const shortenNumber = (value: number): string => {
  if (value < 1000) {
    return value.toString();
  }

  if (value >= 1000 && value < 100000) {
    return `${Math.ceil((value / 1000) * 10) / 10}K`;
  }

  return `${Math.ceil((value / 1000000) * 10) / 10}M`;
};
