import { Avatar, Box, Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { query } from '../hooks';
import Title from '../components/Title';
import { AlternateEmailOutlined, Close, Facebook, Instagram, LinkOutlined, Twitter, X, YouTube } from '@mui/icons-material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { Colors } from '../styles';
import CropperModal from '../components/modals/CropperModal';
import { toast } from 'react-toastify';
import { useAuth } from '../stores';

export default function ProfilePage() {
  const { user } = useAuth();
  const initial = user?.name.charAt(0).toUpperCase() ?? 'S';
  const profileRef = useRef<HTMLInputElement>(null);
  const [profile, setProfile] = useState<string | null>(null);
  const bannerRef = useRef<HTMLInputElement>(null);
  const [banner, setBanner] = useState<string | null>(null);
  const [nickname, setNickname] = useState<{ value: string; error: boolean; helperText: string | null }>({
    value: '',
    error: false,
    helperText: null,
  });
  const [name, setName] = useState<{ value: string; error: boolean; helperText: string | null }>({
    value: '',
    error: false,
    helperText: null,
  });
  const [socials, setSocials] = useState<string[]>([]);
  const [bio, setBio] = useState<string>('');
  const [isEdited, setIsEdited] = useState<boolean>(false);
  const [crop, setCrop] = useState<{
    src: string;
    type: 'avatar' | 'banner';
    onCrop: (file: File) => void;
  } | null>(null);

  useEffect(() => {
    if (user) {
      setProfile(user.profilePath);
      setBanner(user.bannerPath);
      setNickname((prev) => {
        return { ...prev, value: user.nickname };
      });
      setName((prev) => {
        return { ...prev, value: user.name };
      });
      setSocials((prev) => {
        if (user.socials.length === 0) {
          return [''];
        }

        return user.socials;
      });
      setBio(user.bio);
    }

    return () => {};
  }, [user]);

  function handleClickProfile() {
    if (profileRef.current === null) {
      return;
    }

    profileRef.current.click();
  }

  async function handleSelectProfile(event: ChangeEvent<HTMLInputElement>) {
    try {
      const files = event.target.files;
      if (files === null || files.length === 0) {
        return;
      }

      const file = files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setCrop({
          src: reader.result as string,
          type: 'avatar',
          onCrop: handleCropProfile,
        });
      };

      if (profileRef.current !== null) {
        profileRef.current.value = '';
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function handleCropProfile(file: File) {
    try {
      if (!user) {
        return;
      }

      const { upload, url } = await user!.uploadProfile(file.type, 'jpeg');

      await axios.put(upload, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      setProfile(url);
      user.profilePath = url;
      query.setQueryData(['user'], user);
      toast.success('Profile image changed.', { delay: 1500 });
    } catch (e) {
      console.error(e);
    }
  }

  function handleClickBanner() {
    if (bannerRef.current === null) {
      return;
    }

    bannerRef.current.click();
  }

  async function handleSelectBanner(event: ChangeEvent<HTMLInputElement>) {
    try {
      const files = event.target.files;
      if (user === undefined || files === null || files.length === 0) {
        return;
      }

      const file = files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setCrop({
          src: reader.result as string,
          type: 'banner',
          onCrop: handleCropBanner,
        });
      };

      if (bannerRef.current !== null) {
        bannerRef.current.value = '';
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function handleCropBanner(file: File) {
    try {
      if (!user) {
        return;
      }

      const { upload, url } = await user!.uploadBanner(file.type, 'jpeg');

      await axios.put(upload, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      setBanner(url);
      user.bannerPath = url;
      query.setQueryData(['user'], user);
      toast.success('Banner image changed.', { delay: 1500 });
    } catch (e) {
      console.error(e);
    }
  }

  function handleKeyDownNickname(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
    }
  }

  function handleChangeNickname(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value.length > 30) {
      return;
    }

    setNickname({ value: e.target.value.toLowerCase(), error: false, helperText: null });
    setIsEdited(true);
  }

  function handleChangeName(e: ChangeEvent<HTMLInputElement>) {
    setName({ value: e.target.value, error: false, helperText: null });
    setIsEdited(true);
  }

  function handleChangeSocial(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) {
    setSocials((prev) => {
      const after = [...prev];
      after[index] = e.target.value;
      return after;
    });
    setIsEdited(true);
  }

  function handleAddSocial(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setSocials((prev) => [...prev, '']);
  }

  function handleDeleteSocial(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) {
    setSocials((prev) => {
      const after = [...prev];
      after.splice(index, 1);
      return after;
    });
    setIsEdited(true);
  }

  function handleChangeBio(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value.length > 1000) {
      return;
    }

    setBio(e.target.value);
    setIsEdited(true);
  }

  async function handleSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    try {
      if (user === null) {
        return;
      }

      if (!/^[a-z0-9._]{1,30}$/.test(nickname.value)) {
        setNickname((prev) => {
          return { ...prev, error: true, helperText: null };
        });
        return;
      }

      if (name.value === '') {
        setName((prev) => {
          return { ...prev, error: true, helperText: 'Display name is required.' };
        });
        return;
      }

      if (nickname.error || name.error) {
        return;
      }

      const result = await user!.update(
        nickname.value,
        name.value,
        socials.filter((s) => s !== ''),
        bio
      );
      query.setQueryData(['user'], result);
      setIsEdited(false);
      toast.success('Saved.', { delay: 1500 });
    } catch (e) {
      console.error(e);
      if (e instanceof AxiosError) {
        if (e.response?.status === 409) {
          setNickname((prev) => {
            return { ...prev, error: true, helperText: 'This nickname is already in use.' };
          });
        }

        console.info(e.response?.data);
      }
      setIsEdited(true);
    }
  }

  return (
    <>
      <Box>
        <Title title={'Profile'} />
        <Box
          sx={{
            width: '600px',
            height: 'calc(100vh - 140px)',
            maxHeight: '100vh',
            overflow: 'scroll',
            ml: '32px',
            mt: '10px',
            '&::-webkit-scrollbar': {
              display: 'none', // Hides scrollbar for WebKit browsers
            },
            scrollbarWidth: 'none', // Hides scrollbar for Firefox
          }}
        >
          <Box
            sx={{
              position: 'relative',
              height: '230px',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                width: '600px',
                height: '180px',
                top: '0px',
                bottom: '0px',
                backgroundImage: banner && `url(${banner})`,
                backgroundColor: '#BDBDBD',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                '&:hover': {
                  opacity: 0.9,
                  cursor: 'pointer',
                },
              }}
              onClick={handleClickBanner}
            >
              <input ref={bannerRef} type="file" accept={'image/png,image/jpeg'} hidden onChange={handleSelectBanner} />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: '150px',
                left: '40px',
                display: 'flex',
                alignItems: 'center',
                '&:hover': {
                  opacity: 0.9,
                  cursor: 'pointer',
                },
              }}
            >
              <Avatar
                alt={initial}
                src={profile ?? undefined}
                sx={{ width: 80, height: 80, backgroundColor: '#BDBDBD', fontSize: '32px', border: '2px solid white' }}
                onClick={handleClickProfile}
              >
                {!profile && initial}
              </Avatar>
              <input ref={profileRef} type="file" accept={'image/png,image/jpeg'} hidden onChange={handleSelectProfile} />
            </Box>
            <Box sx={{ position: 'absolute', top: '190px', right: '0px', textAlign: 'center' }}>
              <Button variant="contained" sx={{ fontSize: '12px', borderRadius: '20px' }} onClick={handleSubmit} disabled={!isEdited}>
                Save
              </Button>
            </Box>
          </Box>
          <Box sx={{ mt: '25px' }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="nickname"
              name="nickname"
              label="Nickname"
              placeholder="your_unique_nickname"
              autoComplete="nickname"
              type="text"
              onChange={handleChangeNickname}
              onKeyDown={handleKeyDownNickname}
              value={nickname.value}
              error={nickname.error}
              helperText={nickname.helperText ?? 'Maximum 30 characters; lowercase letters, numbers, periods, and underscores only.'}
              rows={1}
              sx={{ my: 0 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ mt: '15px' }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="name"
              name="name"
              label="Display name"
              autoComplete="name"
              type="text"
              onChange={handleChangeName}
              value={name.value}
              error={name.error}
              helperText={name.helperText ?? 'Your name shown to viewers.'}
              rows={1}
              InputLabelProps={{
                shrink: true, // label을 항상 고정
              }}
              sx={{ my: 0 }}
            />
          </Box>
          <Box sx={{ mt: '15px' }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              name="email"
              label="Email"
              placeholder="smth@flex.com"
              autoComplete="email"
              type="text"
              disabled={true}
              value={user?.email ?? ''}
              helperText={'Only used for sign in and creator support, not open externally.'}
              rows={1}
              sx={{ my: 0 }}
            />
          </Box>
          <Box sx={{ mt: '15px' }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="bio"
              name="bio"
              label="Bio"
              autoComplete="bio"
              type="text"
              placeholder="Bio"
              onChange={handleChangeBio}
              value={bio}
              minRows={2}
              maxRows={3}
              multiline={true}
              InputLabelProps={{
                shrink: true, // label을 항상 고정
              }}
              sx={{ my: 0 }}
            />
            <Typography component="div" sx={{ color: 'grey', fontSize: '12px', textAlign: 'end', fontWeight: 500 }}>
              {bio.length}/1000
            </Typography>
          </Box>
          <Box sx={{ mt: '15px' }}>
            <Typography component="div" sx={{ color: 'black', fontSize: '15px', fontWeight: 600, mb: '10px' }}>
              Social URLs
            </Typography>
            {socials.map((social, index) => {
              let icon = <LinkOutlined sx={{ transform: 'rotate(-45deg)' }} />;
              if (social.includes('instagram.com')) {
                icon = <Instagram />;
              } else if (social.includes('x.com')) {
                icon = <X />;
              } else if (social.includes('twitter.com')) {
                icon = <Twitter />;
              } else if (social.includes('youtube.com')) {
                icon = <YouTube />;
              } else if (social.includes('facebook')) {
                icon = <Facebook />;
              }

              const isLast = index === socials.length - 1;
              return (
                <TextField
                  key={index}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id={index.toString()}
                  placeholder="OnlyFans, Fansly, PornHub, Instagram, Twitter etc."
                  autoComplete="url"
                  type="text"
                  onChange={(e) => handleChangeSocial(e, index)}
                  value={social}
                  rows={1}
                  sx={{ mt: 0, mb: isLast ? '5px' : '15px' }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={(e) => handleDeleteSocial(e, index)}>
                          <Close sx={{ color: 'grey' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              );
            })}
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                disableRipple
                variant="text"
                color="primary"
                disabled={socials.length >= 5}
                onClick={handleAddSocial}
                sx={{ color: Colors.secondary, fontSize: '12px', fontWeight: 500, px: 0, mb: '40px', '&:hover': { backgroundColor: 'white' } }}
              >
                + ADD SOCIAL
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {crop && (
        <CropperModal
          open={crop !== null}
          src={crop.src}
          type={crop.type}
          onClose={() => {
            setCrop(null);
          }}
          onCrop={crop.onCrop}
        />
      )}
    </>
  );
}
