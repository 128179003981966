import { Box, Button, DialogActions, DialogContent, Divider, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { useAuth, useVideo } from '../../stores';
import { ChangeEvent, useEffect, useState } from 'react';
import { Video } from '../../models';
import { query } from '../../hooks';
import ModalContainer from './ModalContainer';
import VideoPlayer from '../players/VideoPlayer';
import ThumbnailSection from './edit/ThumbnailSection';
import TeaserSection from './edit/TeaserSection';
import { Colors } from '../../styles';

export default function EditModal() {
  const { user } = useAuth();
  const theme = useTheme();
  const { modal, handleModal, video: editingVideo } = useVideo();
  const [video, setVideo] = useState<Video | null>(null);
  const [title, setTitle] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setVideo(editingVideo);
    setTitle(editingVideo?.title ?? '');

    return () => {};
  }, [editingVideo]);

  function handleClose() {
    if (video !== null) {
      query.invalidateQueries({ queryKey: [user?.id, 'videos'] });
    }

    setVideo(null);
    setTitle('');
    setIsSubmitting(false);
    handleModal(null, null);
  }

  function handleChangeTitle(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value.length > 100) {
      return;
    }

    setTitle(e.target.value);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault(); // Enter 키를 금지
    }
  }

  async function handleSave() {
    if (video === null || isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    await video.update(title, video.status);

    handleClose();
  }

  if (video === null) {
    return <></>;
  }

  return (
    <ModalContainer open={modal === 'EDIT'} title={'Details'} onClose={handleClose}>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'start',
            justifyContent: 'space-between',
            mt: '20px',
            paddingX: '15px',
            [theme.breakpoints.down('lg')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box sx={{ width: '52.5%', minWidth: '400px', mr: '50px' }}>
            <Typography component="div" sx={{ color: 'black', fontSize: '15px', fontWeight: 600, mb: '10px' }}>
              Title (required)
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              name="title"
              placeholder="Add a title that describes your video"
              autoComplete="title"
              type="text"
              autoFocus
              onChange={handleChangeTitle}
              onKeyDown={handleKeyDown}
              value={title}
              rows={2}
              multiline={true}
              sx={{ my: 0 }}
            />
            <Typography component="div" sx={{ color: 'grey', fontSize: '12px', textAlign: 'end', fontWeight: 500 }}>
              {title.length}/100
            </Typography>
            <ThumbnailSection video={video} sx={{ mt: '25px' }} />
            <TeaserSection video={video} sx={{ mt: '25px' }} />
          </Box>
          <Box
            sx={{
              [theme.breakpoints.down('lg')]: {
                mt: '30px',
              },
            }}
          >
            <VideoPlayer video={video} width={'346px'} height={'195px'} />
          </Box>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ px: '20px', py: '16px', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex' }}>
          {video.resolution &&
            Object.entries(video.resolution)
              .filter(([key, value]) => value !== 'UNKNOWN')
              .map(([key, value]) => {
                const resolution = key.toLocaleUpperCase();
                let title = `Converting to ${resolution}...`;
                if (value === 'DONE') {
                  title = `This video supports ${resolution}`;
                }

                return (
                  <Tooltip key={resolution} title={<Typography sx={{ fontSize: '13px' }}>{title}</Typography>} arrow placement="top">
                    <Typography
                      component="div"
                      sx={{ color: value === 'DONE' ? Colors.primary : 'grey', fontSize: '15px', ml: '10px', fontWeight: 600, cursor: 'pointer' }}
                    >
                      {resolution}
                    </Typography>
                  </Tooltip>
                );
              })}
        </Box>
        <Button
          variant="contained"
          disabled={title === '' || isSubmitting}
          onClick={handleSave}
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            borderRadius: '20px',
            pt: '8px',
            pb: '6px',
            px: '18px',
            backgroundColor: 'black',
            ':hover': {
              backgroundColor: 'grey',
            },
          }}
        >
          SAVE
        </Button>
      </DialogActions>
    </ModalContainer>
  );
}
