import { Upload } from '@mui/icons-material';
import { Box, Button, CircularProgress, DialogActions, DialogContent, Link, Typography } from '@mui/material';
import { useAuth, useVideo } from '../../stores';
import { ChangeEvent, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { User } from '../../models';
import { query } from '../../hooks';
import ModalContainer from './ModalContainer';

export default function UploadModal() {
  const { user } = useAuth();
  const { modal, handleModal, upload } = useVideo();
  const [file, setFile] = useState<File | null>(null);

  function handleClose() {
    if (file !== null) {
      query.invalidateQueries({ queryKey: [user?.id, 'videos'] });
    }

    setFile(null);
    handleModal(null);
  }

  async function addVideo(user: User, file: File): Promise<void> {
    setFile(file);

    const video = await user.addVideo(file.name.replace(/\.(mp4|mov)$/i, ''));

    upload(video, file);
    setFile(null);
    handleModal('EDIT', video);
  }

  function handleDrop(files: File[]) {
    if (!user || file !== null || files.length === 0) {
      return;
    }

    addVideo(user, files[0]);
  }

  function handleSelectFile(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (!user || file !== null || files === null || files.length === 0) {
      return;
    }

    addVideo(user, files[0]);
  }

  const { getRootProps } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    accept: {
      'video/mp4': ['.mp4', '.MP4'],
      'video/quicktime': ['.mov', '.MOV'],
    },
  });

  return (
    <ModalContainer {...getRootProps()} open={modal === 'UPLOAD'} title={'Upload Videos'} onClose={handleClose}>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '140px',
              height: '140px',
              borderRadius: '50%',
              backgroundColor: '#f9f9f9',
              mb: '5px',
            }}
          >
            {file === null && <Upload sx={{ fontSize: 70, color: '#9e9e9e' }} />}
            {file !== null && <CircularProgress variant="indeterminate" size={64} thickness={2} />}
          </Box>
          <Typography sx={{ mt: 2, fontSize: '15px', color: file !== null ? '#FF5F15' : 'black' }}>
            {file === null && 'Drag and drop video files to upload'}
            {file !== null && 'Do not leave this screen until complete'}
          </Typography>
          <Typography color="textSecondary" sx={{ fontSize: '13px' }}>
            Your videos will be private until you publish them.
          </Typography>
          <Button
            variant="contained"
            component="label"
            disabled={file !== null}
            sx={{
              mt: 4,
              borderRadius: '20px',
              padding: '8px 24px',
              backgroundColor: 'black',
              ':hover': {
                backgroundColor: 'grey',
              },
            }}
          >
            SELECT FILES
            <input type="file" accept="video/mp4,video/quicktime" hidden onChange={handleSelectFile} disabled={file !== null} />
          </Button>
          <Typography color="textSecondary" sx={{ fontSize: '13px', mt: '3px' }}>
            (MP4, MOV)
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pb: '30px' }}>
        <Typography sx={{ fontSize: '12px', textAlign: 'center', width: '100%', color: '#606060' }}>
          By submitting your videos to SmthFlex, you acknowledge that you agree to SmthFlex's{' '}
          <Link href="/" sx={{ textDecoration: 'none' }}>
            Terms of Service.
          </Link>
          <br />
          Uploading illegally filmed content is punishable under law and may be removed. Please be sure not to violate others' copyright or privacy
          rights.{' '}
          <Link href="/" sx={{ textDecoration: 'none' }}>
            Learn More
          </Link>
        </Typography>
      </DialogActions>
    </ModalContainer>
  );
}
