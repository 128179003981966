import { Box } from '@mui/material';
import { FC } from 'react';

const ImageCard: FC<{ src: string }> = ({ src }) => {
  return (
    <Box
      component="img"
      src={src}
      alt="Error"
      sx={{
        width: '192px',
        minWidth: '192px',
        height: '108px',
        minHeight: '108px',
        border: '1px dashed grey',
        borderRadius: '2px',
        backgroundColor: '#f2f2f2',
        objectFit: 'cover',
      }}
    />
  );
};

export default ImageCard;
