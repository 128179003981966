import { MouseEventHandler, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import Cropper, { Area } from 'react-easy-crop';
import { Colors } from '../../styles';

const CropperModal: React.FC<{
  open: boolean;
  src: string;
  onClose: MouseEventHandler;
  onCrop: (file: File) => void;
  type?: 'thumbnail' | 'avatar' | 'banner';
}> = ({ open, src, onClose, onCrop, type = 'thumbnail' }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const configs = useMemo(() => {
    if (type === 'avatar') {
      return {
        width: 1280,
        height: 1280,
        aspect: 1,
        cropSize: { width: 405, height: 405 },
        cropAreaStyle: { borderRadius: '50%' },
      };
    }

    if (type === 'banner') {
      return {
        width: 1920,
        height: 576,
        aspect: 10 / 3,
        cropSize: { width: 720, height: 216 },
        cropAreaStyle: { borderRadius: '0%' },
      };
    }

    return { width: 1920, height: 1080, aspect: 16 / 9, cropSize: { width: 720, height: 405 }, cropAreaStyle: { borderRadius: '0%' } };
  }, [type]);

  function handleCropComplete(croppedArea: Area, croppedAreaPixels: Area) {
    setCroppedAreaPixels(croppedAreaPixels);
  }

  function handleClose(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    onClose(e);
  }

  async function handleSave(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (croppedAreaPixels === null) {
      handleClose(e);
      return;
    }

    const image = new Image();
    image.src = src;
    await image.decode();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = configs.width;
    canvas.height = configs.height;

    ctx?.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      configs.width,
      configs.height
    );

    const url = await new Promise<string | null>((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          resolve(null);
          return;
        }

        resolve(URL.createObjectURL(blob));
      }, 'image/jpeg');
    });

    if (url !== null) {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], `${new Date().getTime()}.jpeg`, { type: 'image/jpeg' });
      onCrop(file);
    }

    handleClose(e);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ '& .MuiDialog-paper': { borderRadius: '20px', width: '800px', minWidth: '800px', height: '600px', minHeight: '600px' } }}
    >
      <DialogTitle
        sx={{
          pt: '16px',
          pb: '12px',
          px: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Crop</Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ width: '100%', height: '475px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Cropper
            image={src}
            crop={crop}
            zoom={zoom}
            aspect={configs.aspect}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={handleCropComplete}
            minZoom={0.9}
            cropSize={configs.cropSize}
            objectFit="cover"
            style={{
              containerStyle: { position: 'relative', width: '800px', height: '450px' },
              cropAreaStyle: configs.cropAreaStyle,
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: '20px', py: '16px', justifyContent: 'end' }}>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            borderRadius: '20px',
            pt: '8px',
            pb: '6px',
            px: '18px',
            mr: '4px',
            color: 'black',
            backgroundColor: Colors.lightGrey,
            ':hover': {
              backgroundColor: 'grey',
            },
          }}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            borderRadius: '20px',
            pt: '8px',
            pb: '6px',
            px: '18px',
            backgroundColor: 'black',
            ':hover': {
              backgroundColor: 'grey',
            },
          }}
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CropperModal;
