import { PhotoOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { ChangeEvent, ChangeEventHandler, FC, useRef } from 'react';
import { Colors } from '../../../../styles';

const UploadFileCard: FC<{ onChange: ChangeEventHandler<HTMLInputElement>; accept: string }> = ({ onChange, accept }) => {
  const ref = useRef<HTMLInputElement | null>(null);

  function handleClick() {
    if (ref.current === null) {
      return;
    }

    ref.current.click();
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange(event);

    if (ref.current !== null) {
      ref.current.value = '';
    }
  }

  return (
    <Box
      onClick={handleClick}
      sx={{
        width: '192px',
        minWidth: '192px',
        height: '108px',
        minHeight: '108px',
        border: '1px dashed grey',
        borderRadius: '2px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': { backgroundColor: Colors.lightGrey },
      }}
    >
      <PhotoOutlined sx={{ fontSize: '32px', color: 'grey' }} />
      <Typography sx={{ fontSize: '12px', color: 'grey' }}>Upload file</Typography>
      <input ref={ref} type="file" accept={accept} hidden onChange={handleChange} />
    </Box>
  );
};

export default UploadFileCard;
