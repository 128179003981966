import { FC } from 'react';
import ReactPlayer from 'react-player';

const HLSPlayer: FC<{ src: string; thumbnail: string | null }> = ({ src, thumbnail = null }) => {
  return (
    <ReactPlayer
      url={src}
      controls={true}
      loop={false}
      width={'100%'}
      height={'100%'}
      light={thumbnail ?? false}
      pip={false}
      volume={1}
      muted={false}
      config={{
        file: {
          attributes: {
            disablePictureInPicture: true,
            controlsList: 'noplaybackrate nodownload',
            preload: 'auto',
            withcredentials: 'true',
            autoPlay: true,
          },
          hlsOptions: {
            xhrSetup: function (xhr: any) {
              xhr.withCredentials = true;
            },
          },
        },
      }}
    />
  );
};

export default HLSPlayer;
