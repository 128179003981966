import { Box, List, ListItem, Typography } from '@mui/material';
import Title from '../components/Title';
import env from '../env';
import { HelpOutline, PolicyOutlined, VpnKeyOutlined } from '@mui/icons-material';
import PasswordModal from '../components/modals/PasswordModal';
import { useAuth } from '../stores';
import { useState } from 'react';

export default function SettingsPage() {
  const { user } = useAuth();
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);

  function handleHelp() {
    window.open('https://faq.smthflex.com', '_blank');
  }

  function handleTermsOfService() {
    window.open(`${env.REACT_APP_HOST}/termsofservice.html`, '_blank');
  }

  function handlePriavacyPolicy() {
    window.open(`${env.REACT_APP_HOST}/privacy.html`, '_blank');
  }

  return (
    <Box>
      <Title title={'Settings'} />
      <List sx={{ padding: '0 0 0 22px' }}>
        <ListItem key={'password'} onClick={() => setShowPasswordModal(true)} sx={{ backgroundColor: 'white', cursor: 'pointer', p: '12px' }}>
          <VpnKeyOutlined sx={{ fontSize: '24px', marginRight: '12px' }} />
          <Typography sx={{ fontSize: '16px' }}>Change Password</Typography>
        </ListItem>
        <ListItem key={'help'} onClick={handleHelp} sx={{ backgroundColor: 'white', cursor: 'pointer', p: '12px' }}>
          <HelpOutline sx={{ fontSize: '24px', marginRight: '12px' }} />
          <Typography sx={{ fontSize: '16px' }}>Help & Feedback</Typography>
        </ListItem>
        <ListItem key={'termsofservice'} onClick={handleTermsOfService} sx={{ backgroundColor: 'white', cursor: 'pointer', p: '12px' }}>
          <PolicyOutlined sx={{ fontSize: '24px', marginRight: '12px' }} />
          <Typography sx={{ fontSize: '16px' }}>Terms of Service</Typography>
        </ListItem>
        <ListItem key={'privacypolicy'} onClick={handlePriavacyPolicy} sx={{ backgroundColor: 'white', cursor: 'pointer', p: '12px' }}>
          <PolicyOutlined sx={{ fontSize: '24px', marginRight: '12px' }} />
          <Typography sx={{ fontSize: '16px' }}>Privacy Policy</Typography>
        </ListItem>
      </List>
      {showPasswordModal && user && <PasswordModal open={showPasswordModal} user={user} onClose={() => setShowPasswordModal(false)} />}
    </Box>
  );
}
