import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../stores';

export const useBank = () => {
  const { user } = useAuth();

  return useQuery({
    queryKey: [user?.id, 'bank'],
    queryFn: ({ signal }) => {
      return user?.bank(signal);
    },
    enabled: !!user,
    retry: false,
  });
};
