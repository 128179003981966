import { Box, CircularProgress, Tooltip as MaterialToolTip, Typography } from '@mui/material';
import { format, isBefore } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { LineChart, XAxis, YAxis, Line, Tooltip } from 'recharts';
import { Colors } from '../../styles';
import { shortenNumber } from '../../utils';
import { useAnalyticsEarnings } from '../../hooks';
import { HelpOutline } from '@mui/icons-material';

const SAMPLE = [
  { key: new Date(2024, 8, 1), value: 127.15 },
  { key: new Date(2024, 8, 2), value: 130.79 },
  { key: new Date(2024, 8, 3), value: 122.11 },
  { key: new Date(2024, 8, 4), value: 129.94 },
  { key: new Date(2024, 8, 5), value: 150.6 },
  { key: new Date(2024, 8, 6), value: 157.72 },
  { key: new Date(2024, 8, 7), value: 164.47 },
  { key: new Date(2024, 8, 8), value: 151.41 },
  { key: new Date(2024, 8, 9), value: 158.96 },
  { key: new Date(2024, 8, 10), value: 170.51 },
  { key: new Date(2024, 8, 11), value: 182.56 },
  { key: new Date(2024, 8, 12), value: 194.04 },
  { key: new Date(2024, 8, 13), value: 185.28 },
  { key: new Date(2024, 8, 14), value: 181.95 },
  { key: new Date(2024, 8, 15), value: 194.12 },
  { key: new Date(2024, 8, 16), value: 201.36 },
  { key: new Date(2024, 8, 17), value: 198.69 },
  { key: new Date(2024, 8, 18), value: 206.01 },
  { key: new Date(2024, 8, 19), value: 212.84 },
  { key: new Date(2024, 8, 20), value: 219.37 },
  { key: new Date(2024, 8, 21), value: 206.56 },
  { key: new Date(2024, 8, 22), value: 223.79 },
  { key: new Date(2024, 8, 23), value: 224.92 },
  { key: new Date(2024, 8, 24), value: 217.1 },
  { key: new Date(2024, 8, 25), value: 229.61 },
  { key: new Date(2024, 8, 26), value: 231.74 },
  { key: new Date(2024, 8, 27), value: 244.45 },
];

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '0px 10px 0px 10px' }}>
        <p className="label">
          {label}: <b>${payload[0].value.toLocaleString()}</b>
        </p>
      </div>
    );
  }

  return null;
};

const EarningsChart = () => {
  const [data, setData] = useState<{ key: Date; value: number }[] | null>(null);
  const { data: earnings } = useAnalyticsEarnings();

  useEffect(() => {
    if (earnings !== undefined) {
      setData(earnings);
    }

    return () => {};
  }, [earnings]);

  const targets = useMemo(() => {
    if (data === null) {
      return [];
    }

    return (data.length > 0 ? data : SAMPLE)
      .sort((a, b) => {
        return isBefore(a.key, b.key) ? -1 : 1;
      })
      .map((item) => {
        return {
          key: format(item.key, 'MMM dd, yyyy'),
          value: Number(item.value.toFixed(2)),
        };
      });
  }, [data]);

  const ticks = useMemo(() => {
    if (targets.length === 0) {
      return [];
    }

    if (targets.length === 1) {
      return [targets[0].key];
    }

    if (targets.length === 2) {
      return [targets[0].key, targets[1].key];
    }

    return [targets[0].key, targets[Math.floor(targets.length / 2)].key, targets[targets.length - 1].key];
  }, [targets]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        border: '1px solid #ddd',
        borderRadius: '8px',
        width: '620px',
        p: '16px',
      }}
    >
      <Box sx={{ with: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '3px', mb: '20px' }}>
        <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Earnings</Typography>
        <MaterialToolTip
          title={<Typography sx={{ fontSize: '14px' }}>The actual amount paid may differ from the amount agreed upon in the contract.</Typography>}
          arrow
          placement="top"
          enterTouchDelay={100}
          leaveTouchDelay={2000}
        >
          <HelpOutline sx={{ fontSize: '18px' }} />
        </MaterialToolTip>
      </Box>
      <Box sx={{ position: 'relative' }}>
        {!data && (
          <Box sx={{ width: '620px', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={32} thickness={3} />
          </Box>
        )}
        {data && (
          <LineChart
            width={590}
            height={200}
            margin={{
              right: 40,
              left: 20,
            }}
            data={targets}
          >
            <XAxis dataKey="key" ticks={ticks} tickSize={5} tickMargin={10} />
            <YAxis tickFormatter={(value) => `$${shortenNumber(value)}`} />
            <Tooltip content={<CustomTooltip />} />
            <Line type="linear" dataKey="value" stroke={Colors.primary} />
          </LineChart>
        )}
        {data && data.length === 0 && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '620px',
              height: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.85)',
            }}
          >
            <Typography sx={{ color: 'grey', fontSize: '18px' }}>No data</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EarningsChart;
