import { FC } from 'react';
import { Video } from '../models';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { DeleteOutline, WarningAmberOutlined } from '@mui/icons-material';
import { format } from 'date-fns';
import { useVideo } from '../stores';
import Status from './Status';
import { shortenNumber } from '../utils';

const VideoItem: FC<{ video: Video }> = ({ video }) => {
  const { handleModal } = useVideo();
  const { uploaders } = useVideo();
  const uploader = uploaders.find((uploader) => uploader.video.id === video.id) ?? null;

  function handleDelete(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    handleModal('DELETE', video);
  }

  function handleSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();

    if (video.status !== 'DRAFT') {
      return;
    }

    handleModal('SUBMIT', video);
  }

  let container = (
    <Box
      sx={{
        width: '120px',
        height: '68px',
        backgroundColor: '#f9e5e5',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <WarningAmberOutlined sx={{ color: '#990412' }} />
    </Box>
  );

  if (video.thumbnailPath !== null) {
    container = (
      <Box
        component="img"
        src={video.thumbnailPath}
        alt="Error"
        sx={{ width: '120px', height: '68px', objectFit: 'cover', backgroundColor: '#f2f2f2', borderRadius: '8px' }}
      />
    );
  } else if (video.originalPath !== null) {
    container = (
      <Box
        sx={{
          width: '120px',
          height: '68px',
          backgroundColor: '#f4f4f4',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress variant="indeterminate" size={24} thickness={3} />
      </Box>
    );
  } else if (uploader !== null) {
    container = (
      <Box
        sx={{
          width: '120px',
          height: '68px',
          backgroundColor: '#f4f4f4',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography component="div" sx={{ color: 'black', fontSize: '15px' }}>
          {`${uploader.progress.toFixed(1)}%`}
        </Typography>
      </Box>
    );
  }

  let step = <Status video={video} />;
  if (video.status === 'DRAFT') {
    step = (
      <Box>
        <Status video={video} />
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            fontSize: '13px',
            fontWeight: 600,
            borderRadius: '20px',
            mt: '2px',
            pt: '6px',
            pb: '4px',
            px: '14px',
            textTransform: 'none',
            backgroundColor: 'black',
            ':hover': {
              backgroundColor: 'grey',
            },
          }}
        >
          Submit
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', pl: '32px', height: '84px' }}>
      <Box sx={{ flex: 2, minWidth: '382px', display: 'flex', alignItems: 'center' }}>
        {container}
        <Box sx={{ ml: '15px', mr: '10px', minWidth: '230px', maxWidth: '240px', textAlign: 'left' }}>
          <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{video.title}</Typography>
        </Box>
      </Box>
      <Box sx={{ flex: 0.75, minWidth: '100px' }}>{step}</Box>
      <Box sx={{ flex: 1, minWidth: '160px' }}>{format(video.createdAt, 'MMM dd, yyyy HH:mm')}</Box>
      <Box sx={{ flex: 0.5, minWidth: '70px' }}>{shortenNumber(video.views)}</Box>
      <Box sx={{ flex: 0.5, minWidth: '70px' }}>{shortenNumber(video.likes)}</Box>
      <Box sx={{ flex: 0.3, minWidth: '50px' }}>
        <IconButton
          aria-label="Help"
          onClick={handleDelete}
          sx={{
            color: 'black',
            '&:hover': { color: 'white', backgroundColor: 'black' },
          }}
        >
          <DeleteOutline />
        </IconButton>
      </Box>
    </Box>
  );
};

export default VideoItem;
