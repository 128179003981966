export type Currency = 'DOLLAR' | 'WON';

export class Transaction {
  id: number;
  start: Date;
  end: Date;
  licenseAmount: number;
  viewAmount: number;
  totalAmount: number;
  netAmount: number;
  currency: Currency;
  transferredAt: Date;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    id: number,
    start: Date,
    end: Date,
    licenseAmount: number,
    viewAmount: number,
    totalAmount: number,
    netAmount: number,
    currency: Currency,
    transferredAt: Date,
    createdAt: Date,
    updatedAt: Date
  ) {
    this.id = id;
    this.start = start;
    this.end = end;
    this.licenseAmount = licenseAmount;
    this.viewAmount = viewAmount;
    this.totalAmount = totalAmount;
    this.netAmount = netAmount;
    this.currency = currency;
    this.transferredAt = transferredAt;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static fromData(data: { [key: string]: any }): Transaction {
    return new Transaction(
      data['id'] as number,
      new Date(data['start']),
      new Date(data['end']),
      data['licenseAmount'] as number,
      data['viewAmount'] as number,
      data['totalAmount'] as number,
      data['netAmount'] as number,
      data['currency'] as Currency,
      new Date(data['transferredAt']),
      new Date(data['createdAt']),
      new Date(data['updatedAt'])
    );
  }
}
