import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Auth, Connection, User } from '../models';
import { query, useUser } from '../hooks';

interface AuthContextInterface {
  user?: User | null;
  signin(email: string, password: string): Promise<void>;
  signout(): void;
}

const AuthContext = createContext<AuthContextInterface | null>(null);

export const useAuth = (): AuthContextInterface => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const { data, isFetching, refetch } = useUser();

  async function signin(email: string, password: string) {
    const tokens = await Auth.signIn(email, password);
    localStorage.setItem('access_token', tokens.accessToken);
    localStorage.setItem('refresh_token', tokens.refreshToken);
    Connection.instance.setHeaders({ Authorization: `Bearer ${tokens.accessToken}` });
    await refetch();
  }

  function signout() {
    Connection.instance.setHeaders({ Authorization: undefined });
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setUser(null);
    query.clear();
  }

  const auth = useCallback(async () => {
    try {
      let accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');

      if (refreshToken === null) {
        throw Error('Signed Out');
      }

      if (accessToken !== null) {
        Connection.instance.setHeaders({ Authorization: `Bearer ${accessToken}` });
        const { error } = await refetch();
        if (error === null) {
          return;
        }
      }

      Connection.instance.setHeaders({ Authorization: `Bearer ${refreshToken}` });
      accessToken = await Auth.refresh();
      Connection.instance.setHeaders({ Authorization: `Bearer ${accessToken}` });
      localStorage.setItem('access_token', accessToken);
      const { error } = await refetch();
      if (error !== null) throw error;
    } catch (e) {
      Connection.instance.setHeaders({ Authorization: undefined });
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      setUser(null);
      query.clear();
    }
  }, [refetch]);

  useEffect(() => {
    auth();

    return () => {};
  }, [auth]);

  useEffect(() => {
    if (data) {
      setUser(data);
    } else if (user !== undefined && !isFetching) {
      setUser(data ?? null);
    }

    return () => {};
  }, [data, isFetching, user]);

  return <AuthContext.Provider value={{ user, signin, signout }}>{children}</AuthContext.Provider>;
};
