import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Video } from '../../../models';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import UploadFileCard from './cards/UploadFileCard';
import ProgressFileCard from './cards/ProgressFileCard';
import MP4Player from '../../players/MP4Player';
import { MoreVert, AddPhotoAlternateOutlined, DeleteOutline } from '@mui/icons-material';
import axios from 'axios';

interface TeaserSectionProps {
  video: Video;
  [key: string]: any;
}

const TeaserSection: FC<TeaserSectionProps> = ({ video, ...props }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [customTeaserpath, setCustomTeaserPath] = useState<string | null>(null);
  const [isProgress, setIsProgress] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  useEffect(() => {
    setCustomTeaserPath(video.customTeaserPath);
  }, [video.customTeaserPath]);

  function handleMenuClick(event: React.MouseEvent<HTMLElement>) {
    setAnchor(event.currentTarget);
  }

  function handleMenuClose(action?: 'change' | 'delete') {
    if (action === 'change') {
      if (ref.current === null) {
        return;
      }

      ref.current.click();
    }

    if (action === 'delete') {
      handleDeleteTeaser();
    }

    setAnchor(null);
  }

  async function handleChangeTeaser(event: ChangeEvent<HTMLInputElement>) {
    try {
      const files = event.target.files;
      if (video === null || files === null || files.length === 0) {
        return;
      }
      setIsProgress(true);

      const file = files[0];
      let extension = 'mp4';
      if (file.type.includes('quicktime')) {
        extension = 'mov';
      }

      const { upload, url } = await video.uploadCustomTeaser(file.type, extension);

      await axios.put(upload, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      setCustomTeaserPath(url);
    } catch (e) {
      console.error(e);
    } finally {
      setIsProgress(false);
    }
  }

  async function handleDeleteTeaser() {
    try {
      if (customTeaserpath === null) {
        return;
      }

      await video.deleteCustomTeaser();
      setCustomTeaserPath(null);
    } catch (e) {
      console.error(e);
    }
  }

  let customTeaser = <UploadFileCard onChange={handleChangeTeaser} accept="video/mp4,video/quicktime" />;

  if (isProgress) {
    customTeaser = <ProgressFileCard />;
  } else if (customTeaserpath !== null) {
    customTeaser = (
      <Box
        sx={{
          position: 'relative',
          width: '194px', // Consider border width of children
          height: '110px', // Consider border width of children
          border: '1px dashed grey',
          borderRadius: '2px',
          '&:hover .menuButton': {
            opacity: 1,
          },
        }}
      >
        <MP4Player src={customTeaserpath} thumbnail={null} />
        <IconButton
          className="menuButton"
          onClick={handleMenuClick}
          sx={{
            position: 'absolute',
            top: 2,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: 'white',
            opacity: 0,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            },
          }}
        >
          <MoreVert sx={{ fontSize: '20px' }} />
        </IconButton>
        <Menu
          anchorEl={anchor}
          open={anchor !== null}
          onClose={() => handleMenuClose()}
          slotProps={{
            paper: {
              style: {
                borderRadius: '8px',
                boxShadow: '0 3px 5px rgba(0,0,0,0.2)',
              },
            },
          }}
        >
          <MenuItem onClick={() => handleMenuClose('change')}>
            <AddPhotoAlternateOutlined sx={{ mr: 1 }} />
            Change
          </MenuItem>
          <MenuItem onClick={() => handleMenuClose('delete')}>
            <DeleteOutline sx={{ mr: 1 }} />
            Delete
          </MenuItem>
        </Menu>
        <input ref={ref} type="file" accept={'video/mp4,video/quicktime'} hidden onChange={handleChangeTeaser} />
      </Box>
    );
  }

  let teaser = <ProgressFileCard />;

  if (video.teaserPath !== null) {
    teaser = (
      <Box
        sx={{
          width: '192px',
          height: '108px',
          border: '1px dashed grey',
          borderRadius: '2px',
          backgroundColor: '#f2f2f2',
          objectFit: 'cover',
        }}
      >
        <MP4Player src={video.teaserPath} thumbnail={null} />
      </Box>
    );
  }

  return (
    <Box {...props}>
      <Typography component="div" sx={{ color: 'black', fontSize: '15px', fontWeight: 600 }}>
        Teaser
      </Typography>
      <Typography sx={{ color: 'grey', fontSize: '12px', mt: '2px' }}>Set a teaser video that grabs attention and keeps viewers engaged.</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          gap: '20px',
          mt: '12px',
        }}
      >
        {customTeaser}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '3px',
          }}
        >
          {teaser}
          <Typography
            sx={{
              color: 'black',
              fontSize: '10px',
              textAlign: 'end',
            }}
          >
            Auto-generated
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TeaserSection;
