import { useQuery } from '@tanstack/react-query';
import { User } from '../models';

export const useUser = () => {
  return useQuery({
    queryKey: ['user'],
    queryFn: ({ signal }) => {
      return User.me(signal);
    },
    enabled: false,
    retry: false,
  });
};
