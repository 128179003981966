import { AccountBalance } from '@mui/icons-material';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { useBank } from '../../hooks';
import { useState } from 'react';
import BankModal from '../modals/BankModal';

export default function BankSection() {
  const { data: bank } = useBank();
  const [showBankModal, setShowBankModal] = useState<boolean>(false);

  function handleOpenBankModal() {
    setShowBankModal(true);
  }

  function handleCloseBankModal() {
    setShowBankModal(false);
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid #ddd',
        borderRadius: '8px',
        px: '16px',
        pt: '16px',
        pb: '4px',
      }}
    >
      <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '24px' }}>How you get paid</Typography>
      <Box sx={{ minHeight: '108px', display: 'flex' }}>
        {bank === undefined && (
          <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={32} thickness={3} />
          </Box>
        )}
        {bank !== undefined && (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', marginBottom: '8px' }}>
            <Box
              sx={{
                width: '120px',
                height: '80px',
                backgroundColor: 'grey',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AccountBalance sx={{ color: 'white', fontSize: '50px' }} />
            </Box>
            <Box>
              {!bank && <Typography variant="body2">Add bank information to receive your earnings</Typography>}
              {bank && (
                <>
                  <Typography variant="body2">Bank account {bank?.accountNumber}</Typography>
                  <Typography variant="body2">{bank?.name}</Typography>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Button onClick={handleOpenBankModal} disabled={bank === undefined} variant="text" sx={{ marginTop: '16px', py: '12px', fontWeight: 600 }}>
        Manage banking information
      </Button>
      <BankModal open={showBankModal} onClose={handleCloseBankModal} bank={bank} />
    </Box>
  );
}
