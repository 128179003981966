import { Box, CircularProgress, Typography } from '@mui/material';
import { format, isBefore } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { LineChart, XAxis, YAxis, Line, Tooltip } from 'recharts';
import { Colors } from '../../styles';
import { shortenNumber } from '../../utils';
import { useAnalyticsViews } from '../../hooks';

const SAMPLE = [
  { key: new Date(2024, 8, 1), value: 25430 },
  { key: new Date(2024, 8, 2), value: 26157 },
  { key: new Date(2024, 8, 3), value: 24421 },
  { key: new Date(2024, 8, 4), value: 25988 },
  { key: new Date(2024, 8, 5), value: 30120 },
  { key: new Date(2024, 8, 6), value: 31543 },
  { key: new Date(2024, 8, 7), value: 32894 },
  { key: new Date(2024, 8, 8), value: 30281 },
  { key: new Date(2024, 8, 9), value: 31792 },
  { key: new Date(2024, 8, 10), value: 34101 },
  { key: new Date(2024, 8, 11), value: 36512 },
  { key: new Date(2024, 8, 12), value: 38807 },
  { key: new Date(2024, 8, 13), value: 37056 },
  { key: new Date(2024, 8, 14), value: 36389 },
  { key: new Date(2024, 8, 15), value: 38824 },
  { key: new Date(2024, 8, 16), value: 40271 },
  { key: new Date(2024, 8, 17), value: 39738 },
  { key: new Date(2024, 8, 18), value: 41202 },
  { key: new Date(2024, 8, 19), value: 42567 },
  { key: new Date(2024, 8, 20), value: 43873 },
  { key: new Date(2024, 8, 21), value: 41312 },
  { key: new Date(2024, 8, 22), value: 44758 },
  { key: new Date(2024, 8, 23), value: 44983 },
  { key: new Date(2024, 8, 24), value: 43420 },
  { key: new Date(2024, 8, 25), value: 45921 },
  { key: new Date(2024, 8, 26), value: 46348 },
  { key: new Date(2024, 8, 27), value: 48890 },
];

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '0px 10px 0px 10px' }}>
        <p className="label">
          {label}: <b>{payload[0].value.toLocaleString()}</b>
        </p>
      </div>
    );
  }

  return null;
};

const ViewsChart = () => {
  const [data, setData] = useState<{ key: Date; value: number }[] | null>(null);
  const { data: views } = useAnalyticsViews();

  useEffect(() => {
    if (views !== undefined) {
      setData(views);
    }

    return () => {};
  }, [views]);

  const targets = useMemo(() => {
    if (data === null) {
      return [];
    }

    return (data.length > 0 ? data : SAMPLE)
      .sort((a, b) => {
        return isBefore(a.key, b.key) ? -1 : 1;
      })
      .map((item) => {
        return {
          ...item,
          key: format(item.key, 'MMM dd, yyyy'),
        };
      });
  }, [data]);

  const ticks = useMemo(() => {
    if (targets.length === 0) {
      return [];
    }

    if (targets.length === 1) {
      return [targets[0].key];
    }

    if (targets.length === 2) {
      return [targets[0].key, targets[1].key];
    }

    return [targets[0].key, targets[Math.floor(targets.length / 2)].key, targets[targets.length - 1].key];
  }, [targets]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        border: '1px solid #ddd',
        borderRadius: '8px',
        width: '620px',
        p: '16px',
      }}
    >
      <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '20px' }}>Views</Typography>
      <Box sx={{ position: 'relative' }}>
        {!data && (
          <Box sx={{ width: '620px', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={32} thickness={3} />
          </Box>
        )}
        {data && (
          <LineChart
            width={590}
            height={200}
            margin={{
              right: 40,
              left: 20,
            }}
            data={targets}
          >
            <XAxis dataKey="key" ticks={ticks} tickSize={5} tickMargin={10} />
            <YAxis tickFormatter={(value) => shortenNumber(value)} />
            <Tooltip content={<CustomTooltip />} />
            <Line type="linear" dataKey="value" stroke={Colors.secondary} />
          </LineChart>
        )}
        {data && data.length === 0 && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '620px',
              height: '200px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.85)',
            }}
          >
            <Typography sx={{ color: 'grey', fontSize: '18px' }}>No data</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ViewsChart;
