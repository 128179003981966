import { AddRounded } from '@mui/icons-material';
import { Button, Box, Typography } from '@mui/material';
import { useVideo } from '../../stores';
import { Colors } from '../../styles';

export default function UploadButton() {
  const { handleModal } = useVideo();

  return (
    <Button
      variant="outlined"
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderColor: '#e0e0e0',
        borderRadius: '24px',
        pt: '8px',
        pb: '8px',
        pl: '12px',
        pr: '16px',
        textTransform: 'none',
        color: 'black',
        '&:hover': {
          borderColor: Colors.lightGrey,
          backgroundColor: Colors.lightGrey,
        },
      }}
      onClick={() => handleModal('UPLOAD')}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <AddRounded sx={{ fontSize: '22px', marginRight: '2px' }} />
        <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>UPLOAD</Typography>
      </Box>
    </Button>
  );
}
