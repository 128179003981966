import { Avatar, IconButton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../stores';

const ProfileAvatar = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const initial = user?.name.charAt(0).toUpperCase() ?? 'S';

  function handleClick() {
    if (location.pathname === '/profile') {
      return;
    }

    navigate('/profile');
  }

  return (
    <IconButton onClick={handleClick} sx={{ padding: 0, borderRadius: '50%' }}>
      <Avatar alt={initial} src={user?.profilePath ?? undefined} sx={{ width: 40, height: 40, backgroundColor: '#BDBDBD' }}>
        {!user?.profilePath && initial}
      </Avatar>
    </IconButton>
  );
};

export default ProfileAvatar;
