import { ChangeEvent, FormEvent, useState } from 'react';
import Logo1024 from '../assets/logo_1024.png';
import axios, { AxiosError } from 'axios';
import { Alert, Box, Button, Container, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { EmailOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../styles';
import Copyright from '../components/Copyright';
import env from '../env';

export default function ForgotPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [alert, setAlert] = useState<{ severity: 'success' | 'error'; message: string } | null>(null);

  function handleChangeEmail(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
    setAlert(null);
  }

  async function handleSubmit(e: FormEvent<HTMLButtonElement>) {
    try {
      e.preventDefault();

      if (email === '') {
        setAlert({ severity: 'error', message: 'Invalid email.' });
        return;
      }

      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        setAlert({ severity: 'error', message: 'Invalid email.' });
        return;
      }

      setIsResetting(true);

      await axios.post(`${env.REACT_APP_API_ENDPOINT}/password:request`, { email });

      setAlert({ severity: 'success', message: 'Sent reset link to email.' });
    } catch (e) {
      console.error(e);
      if (e instanceof AxiosError) {
        if (e.response?.status === 400) {
          setAlert({ severity: 'error', message: 'Invalid email.' });
        } else {
          setAlert({ severity: 'error', message: 'Server Error.' });
        }
        console.info(e.response?.data);
      }
    } finally {
      setIsResetting(false);
    }
  }

  function handleSignIn() {
    navigate('/signin');
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={Logo1024}
          alt="logo"
          style={{
            width: '60%',
            minWidth: '206px',
            minHeight: '65px',
            marginBottom: '10px',
          }}
        />
        {alert && (
          <Alert severity={alert.severity} sx={{ px: '16px', width: 'calc(85% - 34px)', mb: '10px' }}>
            {alert.message}
          </Alert>
        )}
        <Box component="form" noValidate sx={{ width: '85%' }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleChangeEmail}
            value={email}
            disabled={isResetting}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }} onClick={handleSubmit} disabled={isResetting}>
            SEND RESET LINK TO EMAIL
          </Button>
          <Button fullWidth variant="contained" color="error" sx={{ mt: 0, mb: 2 }} onClick={handleSignIn}>
            BACK TO SIGN IN
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Typography variant="body2">
              Want to become a SmthFlex creator?{' '}
              <Link href="/signup" variant="body2" color={Colors.primary} sx={{ fontWeight: 600 }}>
                Sign Up!
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Copyright />
    </Container>
  );
}
