import { ChangeEvent, FormEvent, useState } from 'react';
import Logo1024 from '../assets/logo_1024.png';
import axios, { AxiosError } from 'axios';
import { Alert, Box, Button, Container, IconButton, InputAdornment, TextField } from '@mui/material';
import { LockOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Colors } from '../styles';
import Copyright from '../components/Copyright';
import env from '../env';

export default function ResetPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  function handleChangePassword(e: ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
    setError(null);
  }

  function handleChangeConfirmPassword(e: ChangeEvent<HTMLInputElement>) {
    setConfirmPassword(e.target.value);
    setError(null);
  }

  async function handleSubmit(e: FormEvent<HTMLButtonElement>) {
    try {
      e.preventDefault();

      if (code === null) {
        setError('Invalid URL.');
        return;
      }

      if (password !== confirmPassword) {
        setError('The Confirm Password does not match.');
        return;
      }

      if (!/^(?=.*\d)(?=.*[!@#$%^&*()_+=\-{}[\]:";'<>?,./~`|\\]).{8,}$/.test(password)) {
        setError('Invalid password.');
        return;
      }

      setIsResetting(true);

      await axios.post(`${env.REACT_APP_API_ENDPOINT}/password:reset`, { code, password });
      navigate('/signin');
    } catch (e) {
      console.error(e);
      if (e instanceof AxiosError) {
        const status = e.response?.status;
        if (status === 400) {
          setError('Invalid Request.');
        } else if (status === 410) {
          setError('Expired URL.');
        } else {
          setError('Server Error.');
        }
        console.info(e.response?.data);
      }
    } finally {
      setIsResetting(false);
    }
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={Logo1024}
          alt="logo"
          style={{
            width: '60%',
            minWidth: '206px',
            minHeight: '65px',
            marginBottom: '10px',
          }}
        />
        {error && (
          <Alert severity="error" sx={{ px: '16px', width: 'calc(85% - 34px)', mb: '10px' }}>
            {error}
          </Alert>
        )}
        <Box component="form" noValidate sx={{ width: '85%' }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="new-password"
            name="New Password"
            label="New Password"
            type={showPassword ? 'text' : 'password'}
            autoComplete="password"
            onChange={handleChangePassword}
            onClick={() => setError(null)}
            value={password}
            disabled={isResetting}
            helperText={'At least 1 number, 1 special character, and be 8+ characters long.'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                    <VisibilityOutlined sx={{ color: showPassword ? Colors.secondary : 'grey' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="confirm-password"
            label="Confirm Password"
            name="Confirm Password"
            type={showPassword ? 'text' : 'password'}
            autoComplete="password"
            onChange={handleChangeConfirmPassword}
            onClick={() => setError(null)}
            value={confirmPassword}
            disabled={isResetting}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                    <VisibilityOutlined sx={{ color: showPassword ? Colors.secondary : 'grey' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }} onClick={handleSubmit} disabled={isResetting}>
            RESET PASSWORD
          </Button>
        </Box>
      </Box>
      <Copyright />
    </Container>
  );
}
