import { Connection } from './connection';

export interface VideoCreator {
  id: number;
  nickname: string;
  name: string;
  profilePath: string | null;
}

type VideoResolutionStatus = 'UNKNOWN' | 'PROCESSING' | 'DONE';
export interface VideoResolution {
  hd?: VideoResolutionStatus;
  fhd?: VideoResolutionStatus;
  uhd?: VideoResolutionStatus;
}

export type VideoStatus = 'DRAFT' | 'REVIEWING' | 'PRIVATE' | 'PUBLIC' | 'DELETED' | 'REJECTED';

export class Video {
  id: number;
  title: string;
  status: VideoStatus;
  originalPath: string | null;
  streamingPath: string | null;
  thumbnailPath: string | null;
  customThumbnailPath: string | null;
  teaserPath: string | null;
  customTeaserPath: string | null;
  resolution: VideoResolution;
  duration: number | null;
  views: number;
  likes: number;
  createdAt: Date;
  updatedAt: Date;
  creator: VideoCreator;

  constructor(
    id: number,
    title: string,
    status: VideoStatus,
    originalPath: string | null,
    streamingPath: string | null,
    thumbnailPath: string | null,
    customThumbnailPath: string | null,
    teaserPath: string | null,
    customTeaserPath: string | null,
    resolution: VideoResolution,
    duration: number | null,
    views: number,
    likes: number,
    createdAt: Date,
    updatedAt: Date,
    creator: VideoCreator
  ) {
    this.id = id;
    this.title = title;
    this.status = status;
    this.originalPath = originalPath;
    this.streamingPath = streamingPath;
    this.thumbnailPath = thumbnailPath;
    this.customThumbnailPath = customThumbnailPath;
    this.teaserPath = teaserPath;
    this.customTeaserPath = customTeaserPath;
    this.resolution = resolution;
    this.duration = duration;
    this.views = views;
    this.likes = likes;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.creator = creator;
  }

  static fromData(data: { [key: string]: any }): Video {
    return new Video(
      data['id'] as number,
      data['title'] as string,
      data['status'] as VideoStatus,
      data['originalPath'] as string | null,
      data['streamingPath'] as string | null,
      data['thumbnailPath'] as string | null,
      data['customThumbnailPath'] as string | null,
      data['teaserPath'] as string | null,
      data['customTeaserPath'] as string | null,
      data['resolution'] as VideoResolution,
      data['duration'] as number | null,
      data['views'] as number,
      data['likes'] as number,
      data['createdAt'] as Date,
      data['updatedAt'] as Date,
      data['creator'] as VideoCreator
    );
  }

  async update(title: string, status: VideoStatus): Promise<Video> {
    const data = await Connection.instance.put(`/users/${this.creator.id}/videos/${this.id}`, { title, status });
    return Video.fromData(data['video']);
  }

  async delete(): Promise<Video> {
    const data = await Connection.instance.delete(`/users/${this.creator.id}/videos/${this.id}`);
    return Video.fromData(data['video']);
  }

  async uploadVideo(contentType: string, extension: string): Promise<{ upload: string; url: string }> {
    const data = await Connection.instance.post(`/users/${this.creator.id}/videos/${this.id}/video`, { contentType, extension });
    return { upload: data['upload'], url: data['url'] };
  }

  /**
   * Start Multi-Part Upload
   */
  async startUploadVideo(
    contentType: string,
    extension: string,
    partCount: number
  ): Promise<{ uploads: { url: string; partNumber: number }[]; key: string; uploadId: string; url: string }> {
    const data = await Connection.instance.post(`/users/${this.creator.id}/videos/${this.id}/video:start`, { contentType, extension, partCount });
    return { uploads: data['uploads'], key: data['key'], uploadId: data['uploadId'], url: data['url'] };
  }

  /**
   * Complete Multi-Part Upload
   */
  async completeUploadVideo(parts: { etag: string; partNumber: number }[], uploadId: string, key: string): Promise<void> {
    await Connection.instance.post(`/users/${this.creator.id}/videos/${this.id}/video:complete`, { parts, uploadId, key });
    return;
  }

  async uploadCustomThumbnail(contentType: string, extension: string): Promise<{ upload: string; url: string }> {
    const data = await Connection.instance.post(`/users/${this.creator.id}/videos/${this.id}/thumbnail`, { contentType, extension });
    return { upload: data['upload'], url: data['url'] };
  }

  async deleteCustomThumbnail(): Promise<Video> {
    const data = await Connection.instance.delete(`/users/${this.creator.id}/videos/${this.id}/thumbnail`);
    return Video.fromData(data['video']);
  }

  async uploadCustomTeaser(contentType: string, extension: string): Promise<{ upload: string; url: string }> {
    const data = await Connection.instance.post(`/users/${this.creator.id}/videos/${this.id}/teaser`, { contentType, extension });
    return { upload: data['upload'], url: data['url'] };
  }

  async deleteCustomTeaser(): Promise<Video> {
    const data = await Connection.instance.delete(`/users/${this.creator.id}/videos/${this.id}/teaser`);
    return Video.fromData(data['video']);
  }
}
