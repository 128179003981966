import { Box, Button } from '@mui/material';
import Title from '../components/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import RegionChart from '../components/analytics/RegionChart';
import ViewsChart from '../components/analytics/ViewsChart';
import EarningsChart from '../components/analytics/EarningsChart';
import GenderChart from '../components/analytics/GenderChart';
import AgeChart from '../components/analytics/AgeChart';
import ComingSoonImg from '../assets/coming_soon.png';

export default function AnalyticsPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const isOverview = useMemo(() => location.pathname !== '/analytics/videos', [location.pathname]);

  function handleNavigate(route: string) {
    if (location.pathname === route) {
      return;
    }

    navigate(route);
  }

  return (
    <Box>
      <Title title={'Analytics'} />
      <Box sx={{ ml: '32px', mr: '32px', display: 'flex', flexDirection: 'column', gap: '18px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0px', height: '40px' }}>
          <Button
            variant="text"
            sx={{
              width: '128px',
              fontSize: '15px',
              fontWeight: 600,
              textTransform: 'none',
              color: isOverview ? 'black' : 'grey',
              borderRadius: 0,
              borderBottom: `1px solid ${isOverview ? 'black' : 'white'}`,
            }}
            onClick={() => handleNavigate('/analytics/overview')}
          >
            Overview
          </Button>
          <Button
            variant="text"
            sx={{
              width: '128px',
              fontSize: '15px',
              fontWeight: 600,
              textTransform: 'none',
              color: isOverview ? 'grey' : 'black',
              borderRadius: 0,
              borderBottom: `1px solid ${isOverview ? 'white' : 'black'}`,
            }}
            onClick={() => handleNavigate('/analytics/videos')}
          >
            Videos
          </Button>
        </Box>
        {isOverview && (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '25px' }}>
              <EarningsChart />
              <ViewsChart />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
              <RegionChart />
              <AgeChart />
              <GenderChart />
            </Box>
          </>
        )}
        {!isOverview && (
          <Box
            sx={{
              width: '100%',
              minWidth: '860px',
              height: '600px',
              backgroundColor: 'rgba(255, 255, 255, 0.85)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={ComingSoonImg} alt="Comming Soon!" style={{ width: '256px' }} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
