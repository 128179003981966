import { Box, Typography, CircularProgress, Button, Link } from '@mui/material';
import { format, isBefore } from 'date-fns';
import { useTransactions } from '../../hooks';

export default function TransactionsSection() {
  const { data: transactions } = useTransactions();

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid #ddd',
        borderRadius: '8px',
        px: '16px',
        pt: '16px',
        pb: '4px',
      }}
    >
      <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '24px' }}>Transactions</Typography>
      <Box sx={{ minHeight: '108px', display: 'flex' }}>
        {!transactions && (
          <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={32} thickness={3} />
          </Box>
        )}
        {transactions && (
          <>
            {transactions.length === 0 && (
              <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Typography sx={{ color: 'grey', fontSize: '14px' }}>You don't have any transactions yet</Typography>
              </Box>
            )}
            {transactions.length > 0 && (
              <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: '8px' }}>
                {transactions
                  .sort((a, b) => (isBefore(a.start, b.start) ? 1 : -1))
                  .slice(0, transactions.length > 4 ? 4 : transactions.length)
                  .map((transaction) => {
                    return (
                      <Box key={transaction.id} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Link href="/earnings" sx={{ textDecoration: 'none', fontSize: '14px', fontWeight: 600 }}>
                          {format(transaction.start, `MMM ${transaction.start.getUTCDate()} - ${transaction.end.getUTCDate()} yyyy`)}
                        </Link>
                        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>${transaction.totalAmount.toLocaleString()}</Typography>
                      </Box>
                    );
                  })}
              </Box>
            )}
          </>
        )}
      </Box>
      <Button variant="text" disabled={!transactions || transactions.length === 0} sx={{ marginTop: '16px', py: '12px', fontWeight: 600 }}>
        View transactions
      </Button>
    </Box>
  );
}
