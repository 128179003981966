import { Connection } from './connection';
import { User } from './user';

export class Auth {
  static async signIn(email: string, password: string): Promise<{ accessToken: string; refreshToken: string }> {
    const data = await Connection.instance.post('/auth/tokens', { email, password });
    return {
      accessToken: data['accessToken'],
      refreshToken: data['refreshToken'],
    };
  }

  static async signUp(nickname: string, email: string, password: string): Promise<{ user: User; accessToken: string; refreshToken: string }> {
    const data = await Connection.instance.post('/users', { nickname, email, password });
    return {
      user: User.fromData(data['user']),
      accessToken: data['accessToken'],
      refreshToken: data['refreshToken'],
    };
  }

  static async refresh(): Promise<string> {
    const data = await Connection.instance.post('/auth/refresh');
    return data['accessToken'];
  }
}
