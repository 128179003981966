import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import RootLayout from './layouts/Root';
import HomeLayout from './layouts/Home';
import VideosPage from './pages/Videos';
import SignUpPage from './pages/SignUp';
import SignInPage from './pages/SignIn';
import ProfilePage from './pages/Profile';
import AnalyticsPage from './pages/Analytics';
import EarningsPage from './pages/Earnings';
import SettingsPage from './pages/Settings';
import { QueryClientProvider } from '@tanstack/react-query';
import ForgotPage from './pages/Forgot';
import ResetPage from './pages/Reset';
import { query } from './hooks';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '/',
        element: <HomeLayout />,
        children: [
          {
            index: true,
            element: <VideosPage />,
          },
          {
            path: '/profile',
            element: <ProfilePage />,
          },
          {
            path: '/videos',
            element: <VideosPage />,
          },
          {
            path: '/earnings',
            element: <EarningsPage />,
            children: [
              {
                index: true,
                element: <EarningsPage />,
              },
              {
                path: '/earnings/*',
                element: <EarningsPage />,
              },
            ],
          },
          {
            path: '/analytics',
            element: <AnalyticsPage />,
            children: [
              {
                index: true,
                element: <AnalyticsPage />,
              },
              {
                path: '/analytics/*',
                element: <AnalyticsPage />,
              },
            ],
          },
          {
            path: '/settings',
            element: <SettingsPage />,
          },
        ],
      },
      { path: '/signin', element: <SignInPage /> },
      { path: '/signup', element: <SignUpPage /> },
      { path: '/forgot', element: <ForgotPage /> },
      { path: '/reset', element: <ResetPage /> },
    ],
  },
  { path: '*', element: <Navigate to="/" replace /> },
]);

function App() {
  return (
    <QueryClientProvider client={query}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
