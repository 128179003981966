import { Box, LinearProgress, Typography } from '@mui/material';
import { format, isBefore } from 'date-fns';
import { useTransactions } from '../../hooks';
import { useEffect, useState } from 'react';
import { Transaction } from '../../models';

export default function EarningsSection() {
  const { data: transactions } = useTransactions();
  const [transaction, setTransaction] = useState<Transaction | null>(null);

  useEffect(() => {
    if (transactions && transactions.length > 0) {
      const targets = transactions.sort((a, b) => (isBefore(a.transferredAt, b.transferredAt) ? 1 : -1));
      setTransaction(targets[0]);
    }

    return () => {};
  }, [transactions]);

  return (
    <Box sx={{ border: '1px solid #ddd', borderRadius: '8px', padding: '16px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: '24px' }}>
        <Box>
          <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '12px' }}>Your earnings</Typography>
          <Typography sx={{ fontSize: '14px' }}>Paid monthly if the total is at least $50 (your payment threshold)</Typography>
        </Box>
        <Typography sx={{ fontSize: '30px' }}>$0</Typography>
      </Box>
      <LinearProgress variant="determinate" value={0} sx={{ height: '8px', borderRadius: '4px', marginBottom: '0px' }} />
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: '24px' }}>
        <Typography variant="caption" sx={{ color: '#888' }}>
          You've reached 0% of your payment threshold
        </Typography>
        <Typography variant="caption" sx={{ color: '#888' }}>
          Payment threshold: $50
        </Typography>
      </Box>
      {transaction && (
        <Typography sx={{ fontSize: '14px', display: 'flex', alignItems: 'center' }}>
          <span role="img" aria-label="calendar" style={{ marginRight: '8px' }}>
            📅
          </span>
          Your last payment was issued on&nbsp;<strong>{format(transaction.transferredAt, 'MMM dd')}</strong>&nbsp;for&nbsp;
          <strong>${transaction.netAmount.toLocaleString()}</strong>
        </Typography>
      )}
    </Box>
  );
}
