import { Box, CircularProgress } from '@mui/material';

const ProgressFileCard = () => {
  return (
    <Box
      sx={{
        width: '192px',
        minWidth: '192px',
        height: '108px',
        minHeight: '108px',
        border: '1px dashed grey',
        borderRadius: '2px',
        backgroundColor: '#f2f2f2',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={24} thickness={3} />
    </Box>
  );
};

export default ProgressFileCard;
