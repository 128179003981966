import { Box } from '@mui/material';
import Title from '../components/Title';
import BankSection from '../components/earnings/BankSection';
import TransactionsSection from '../components/earnings/TransactionsSection';
import EarningsSection from '../components/earnings/EarningsSection';

export default function EarningsPage() {
  return (
    <Box>
      <Title title={'Earnings'} />
      <Box
        sx={{ width: '60%', minWidth: '600px', maxWidth: '1000px', display: 'flex', gap: '16px', flexDirection: 'column', ml: '32px', mt: '10px' }}
      >
        <EarningsSection />
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <TransactionsSection />
          <BankSection />
        </Box>
      </Box>
    </Box>
  );
}
