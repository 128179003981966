import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../stores';

export const useAnalyticsRegions = () => {
  const { user } = useAuth();

  return useQuery({
    queryKey: [user?.id, 'analytics', 'regions'],
    queryFn: ({ signal }) => {
      return user?.regions(signal) ?? [];
    },
    enabled: !!user,
    retry: false,
  });
};
