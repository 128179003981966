import { useMemo, useState } from 'react';
import { Video, VideoStatus } from '../models';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { BlockOutlined, HourglassEmptyOutlined, InsertDriveFileOutlined, LockOutlined, PublicOutlined } from '@mui/icons-material';

const Status: React.FC<{ video: Video }> = ({ video }) => {
  const [status, setStatus] = useState<VideoStatus>(video.status);

  const text = useMemo(() => {
    if (status === 'DRAFT') {
      return 'Draft';
    }

    if (status === 'REVIEWING') {
      return 'In Review';
    }

    if (status === 'PRIVATE') {
      return 'Private';
    }

    if (status === 'PUBLIC') {
      return 'Public';
    }

    if (status === 'REJECTED') {
      return 'Rejected';
    }

    return status;
  }, [status]);

  function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
  }

  function handleChange(e: SelectChangeEvent<'PRIVATE' | 'PUBLIC'>) {
    if (e.target.value !== 'PRIVATE' && e.target.value !== 'PUBLIC') {
      return;
    }

    setStatus(e.target.value);
    video.update(video.title, e.target.value);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px' }}>
      {status === 'DRAFT' && <InsertDriveFileOutlined sx={{ fontSize: '18px' }} />}
      {status === 'REVIEWING' && <HourglassEmptyOutlined sx={{ fontSize: '18px' }} />}
      {status === 'PRIVATE' && <LockOutlined sx={{ fontSize: '18px' }} />}
      {status === 'PUBLIC' && <PublicOutlined sx={{ fontSize: '18px' }} />}
      {status === 'REJECTED' && <BlockOutlined sx={{ fontSize: '18px' }} />}
      {status !== 'PUBLIC' && status !== 'PRIVATE' && <Typography sx={{ fontSize: '16px' }}>{text}</Typography>}
      {(status === 'PUBLIC' || status === 'PRIVATE') && (
        <FormControl onClick={handleClick} variant="standard">
          <Select labelId="type" value={status} disableUnderline onChange={handleChange} sx={{ fontSize: '16px' }}>
            <MenuItem value="PRIVATE">Private</MenuItem>
            <MenuItem value="PUBLIC">Public</MenuItem>
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default Status;
