import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import ModalContainer from './ModalContainer';
import { UserBank, UserBankType } from '../../models';
import { useAuth } from '../../stores';
import { query } from '../../hooks';

interface BankModalProps {
  bank?: UserBank | null;
  onClose: () => void;
  open: boolean;
}

interface BankData {
  name: string;
  type: UserBankType;
  bankName: string;
  bankAddress: string;
  accountNumber: string;
  routingNumber: string;
  swiftCode: string;
  state: string;
  city: string;
  address: string;
  postalCode: string;
}

const initialData: BankData = {
  name: '',
  type: 'INDIVIDUAL',
  bankName: '',
  bankAddress: '',
  accountNumber: '',
  routingNumber: '',
  swiftCode: '',
  state: '',
  city: '',
  address: '',
  postalCode: '',
};

const BankModal: React.FC<BankModalProps> = ({ bank, open, onClose }) => {
  const { user } = useAuth();
  const [data, setData] = useState<BankData>(initialData);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const isDisabled = data.name === '' || data.bankName === '' || data.bankAddress === '' || data.accountNumber === '';

  useEffect(() => {
    if (bank) {
      setData({
        name: bank.name,
        type: bank.type,
        bankName: bank.bankName,
        bankAddress: bank.bankAddress,
        accountNumber: bank.accountNumber,
        routingNumber: bank.routingNumber ?? '',
        swiftCode: bank.swiftCode ?? '',
        state: bank.state ?? '',
        city: bank.city ?? '',
        address: bank.address ?? '',
        postalCode: bank.postalCode ?? '',
      });
    }
  }, [bank]);

  function handleClose() {
    setData(initialData);
    setIsSubmitting(false);
    onClose();
  }

  function handleChange(key: string, e: ChangeEvent<HTMLInputElement>) {
    setData((prev) => ({ ...prev, [key]: e.target.value }));
  }

  function handleChangeType(e: SelectChangeEvent<'INDIVIDUAL' | 'COMPANY'>) {
    setData((prev) => ({ ...prev, type: e.target.value as UserBankType }));
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault(); // Enter 키를 금지
    }
  }

  async function handleSave() {
    if (!user || isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const bank = await user.updateBank(
      data.name,
      data.type,
      data.bankName,
      data.bankAddress,
      data.accountNumber,
      data.routingNumber === '' ? null : data.routingNumber,
      data.swiftCode === '' ? null : data.swiftCode,
      data.state === '' ? null : data.state,
      data.city === '' ? null : data.city,
      data.address === '' ? null : data.address,
      data.postalCode === '' ? null : data.postalCode
    );
    query.setQueryData([user.id, 'bank'], bank);

    handleClose();
  }

  return (
    <ModalContainer open={open} onClose={handleClose} title={'Banking Information'} maxWidth="sm">
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', mt: '20px' }}>
          <Typography variant="body2" sx={{ marginBottom: '5px' }}>
            To change your <b>Account Number or IBAN</b>, please contact <b>support@smthflex.com</b>.
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Full Name"
            name="name"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('name', e)}
            onKeyDown={handleKeyDown}
            value={data.name}
            helperText={'Please enter your full name as on your bank account'}
            sx={{ my: 0 }}
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel id="type">Legal Type</InputLabel>
            <Select labelId="type" value={data.type} label="Legal Type" onChange={handleChangeType}>
              <MenuItem value="INDIVIDUAL">Individual</MenuItem>
              <MenuItem value="COMPANY">Company</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="bank_name"
            label="Bank Name"
            name="bank_name"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('bankName', e)}
            onKeyDown={handleKeyDown}
            value={data.bankName}
            sx={{ my: 0 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="bank_address"
            label="Bank Address"
            name="bank_address"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('bankAddress', e)}
            onKeyDown={handleKeyDown}
            value={data.bankAddress}
            sx={{ my: 0 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            disabled={!!bank}
            id="account_number"
            label="Account Number or IBAN"
            name="account_number"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('accountNumber', e)}
            onKeyDown={handleKeyDown}
            value={data.accountNumber}
            sx={{ my: 0 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="routing_number"
            label="Routing Number or Bank Code (optional)"
            name="routing_number"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('routingNumber', e)}
            onKeyDown={handleKeyDown}
            value={data.routingNumber}
            sx={{ my: 0 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="swift_code"
            label="Swift Code (optional)"
            name="swift_code"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('swiftCode', e)}
            onKeyDown={handleKeyDown}
            value={data.swiftCode}
            sx={{ my: 0 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="state"
            label="State or Province (optional)"
            name="state"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('state', e)}
            onKeyDown={handleKeyDown}
            value={data.state}
            sx={{ my: 0 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="city"
            label="City (optional)"
            name="city"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('city', e)}
            onKeyDown={handleKeyDown}
            value={data.city}
            sx={{ my: 0 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="address"
            label="Address (optional)"
            name="address"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('address', e)}
            onKeyDown={handleKeyDown}
            value={data.address}
            sx={{ my: 0 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="postal_code"
            label="Postal Code or ZIP (optional)"
            name="postal_code"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange('postalCode', e)}
            onKeyDown={handleKeyDown}
            value={data.postalCode}
            sx={{ my: 0 }}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ px: '20px', py: '16px', justifyContent: 'end' }}>
        <Button
          variant="contained"
          disabled={!user || isDisabled || isSubmitting}
          onClick={handleSave}
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            borderRadius: '20px',
            pt: '8px',
            pb: '6px',
            px: '18px',
            backgroundColor: 'black',
            ':hover': {
              backgroundColor: 'grey',
            },
          }}
        >
          SAVE
        </Button>
      </DialogActions>
    </ModalContainer>
  );
};

export default BankModal;
