import { Box, Typography } from '@mui/material';

export default function Copyright() {
  return (
    <Box
      sx={{
        position: 'fixed', // 고정된 위치
        bottom: 5, // 화면 하단
        left: '50%', // 가로 중앙
        transform: 'translateX(-50%)', // 가운데 정렬
        textAlign: 'center', // 텍스트 가운데 정렬
        width: '100%',
        py: 2,
      }}
    >
      <Typography sx={{ color: 'grey', fontSize: '14px' }}>Cuppasoft © 2024 All Rights Reserved.</Typography>
    </Box>
  );
}
