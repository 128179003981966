import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Typography } from '@mui/material';
import { useVideo } from '../../stores';
import { useEffect, useState } from 'react';
import { Video } from '../../models';
import { query } from '../../hooks';
import { format } from 'date-fns';
import { WarningAmberOutlined } from '@mui/icons-material';
import { Colors } from '../../styles';

export default function DeleteModal() {
  const { modal, handleModal, video: deletingVideo } = useVideo();
  const [video, setVideo] = useState<Video | null>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setVideo(deletingVideo);
    return () => {};
  }, [deletingVideo]);

  function handleDelete() {
    if (video === null) {
      return;
    }

    query.setQueryData([video.creator.id, 'videos'], (videos: Video[]) => videos.filter((v) => v.id !== video.id));
    video.delete();
    handleClose();
  }

  function handleClose() {
    setVideo(null);
    setIsChecked(false);
    handleModal(null);
  }

  function handleCheck(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    setIsChecked(checked);
  }

  if (video === null) {
    return <></>;
  }

  let thumbnail = (
    <Box
      sx={{
        width: '120px',
        height: '68px',
        backgroundColor: '#f9e5e5',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <WarningAmberOutlined sx={{ color: '#990412' }} />
    </Box>
  );

  if (video.thumbnailPath !== null) {
    thumbnail = (
      <Box
        component="img"
        src={video.thumbnailPath}
        alt="Error"
        sx={{ width: '120px', height: '68px', objectFit: 'cover', backgroundColor: '#f2f2f2', borderRadius: '8px' }}
      />
    );
  }

  return (
    <Dialog open={modal === 'DELETE'} onClose={handleClose} sx={{ '& .MuiDialog-paper': { borderRadius: '20px', height: '330px', width: '600px' } }}>
      <DialogTitle
        sx={{
          pt: '16px',
          pb: '12px',
          px: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Permanently delete this video?</Typography>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
            width: '100%',
            height: '100px',
            backgroundColor: Colors.lightGrey,
            mb: '17px',
          }}
        >
          <Box sx={{ ml: '15px', mr: '15px', display: 'flex' }}>{thumbnail}</Box>
          <Box>
            <Typography sx={{ fontSize: '13px' }}>{video.title}</Typography>
            <Typography sx={{ fontSize: '12px', color: 'grey' }}>Uploaded {format(video.createdAt, 'MMM dd, yyyy')}</Typography>
            <Typography sx={{ fontSize: '12px', color: 'grey' }}>4,189 views</Typography>
          </Box>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheck}
              sx={{
                mr: '2px',
                '&.Mui-checked': {
                  color: 'black',
                },
                '&.Mui-checked:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
          }
          label="I understand that deleting a video from SmthFlex is permanent and cannot be undone."
          componentsProps={{ typography: { fontSize: '14px' } }}
        />
      </DialogContent>
      <DialogActions sx={{ px: '20px', pt: '12px', pb: '18px', justifyContent: 'end' }}>
        <Button
          variant="contained"
          component="label"
          onClick={handleClose}
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            borderRadius: '20px',
            pt: '8px',
            pb: '6px',
            px: '18px',
            mr: '4px',
            color: 'black',
            backgroundColor: Colors.lightGrey,
            ':hover': {
              backgroundColor: 'grey',
            },
          }}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          component="label"
          onClick={handleDelete}
          disabled={!isChecked}
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            borderRadius: '20px',
            pt: '8px',
            pb: '6px',
            px: '18px',
            backgroundColor: 'black',
            ':hover': {
              backgroundColor: 'grey',
            },
          }}
        >
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
