import { Box } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from '../components/sidebar/Sidebar';
import Header from '../components/Header';
import { zIndex } from '../styles';
import UploadModal from '../components/modals/UploadModal';
import { useAuth, VideoProvider } from '../stores';
import EditModal from '../components/modals/EditModal';
import SubmitModal from '../components/modals/SubmitModal';
import DeleteModal from '../components/modals/DeleteModal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';

export default function HomeLayout() {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user === null) {
      navigate('/signin');
    }

    return () => {};
  }, [user, navigate]);

  return (
    <VideoProvider>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
        <header style={{ zIndex: zIndex.header }}>
          <Header />
        </header>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
          }}
        >
          <aside style={{ zIndex: zIndex.sidebar }}>
            <Sidebar />
          </aside>
          <Box sx={{ flexGrow: 1 }}>
            <main style={{ zIndex: zIndex.main }}>
              <Outlet />
              <UploadModal />
              <EditModal />
              <SubmitModal />
              <DeleteModal />
            </main>
          </Box>
        </Box>
        <ToastContainer />
      </Box>
    </VideoProvider>
  );
}
