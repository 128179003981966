import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

const Title: FC<{ title: string; trailing?: ReactNode }> = ({ title, trailing }) => {
  return (
    <Box sx={{ pl: '32px', pr: '20px', pt: '23px', pb: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography sx={{ fontSize: '25px', fontWeight: 600 }}>{title}</Typography>
      {trailing && <Box>{trailing}</Box>}
    </Box>
  );
};

export default Title;
