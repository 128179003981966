import { Logout } from '@mui/icons-material';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useAuth } from '../../stores';

const SignOutButton = () => {
  const { signout } = useAuth();

  function handleSignOut() {
    signout();
  }

  return (
    <ListItem
      sx={{
        padding: 0,
        boxSizing: 'border-box',
      }}
    >
      <ListItemButton
        aria-label={'Sign Out'}
        onClick={handleSignOut}
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          pt: 2,
          px: 1,
          borderRadius: '12px',
          width: '100%',
          '& .MuiListItemIcon-root, & .MuiListItemText-root': {
            color: 'black',
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            '& svg': {
              fontSize: '24px',
            },
          }}
        >
          <Logout />
        </ListItemIcon>
        <ListItemText primary={'Sign Out'} primaryTypographyProps={{ fontSize: '12px' }} sx={{ textAlign: 'center' }} />
      </ListItemButton>
    </ListItem>
  );
};

export default SignOutButton;
