import { CloseSharp } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import { FC, forwardRef, MouseEventHandler, ReactNode } from 'react';

interface ModalContainerProps {
  children: ReactNode;
  open: boolean;
  title: string;
  onClose: MouseEventHandler;
  maxWidth?: 'sm' | 'md' | 'lg';
  [key: string]: any;
}

const ModalContainer: FC<ModalContainerProps> = forwardRef<HTMLDivElement, ModalContainerProps>(
  ({ children, open, title, onClose, maxWidth = 'lg', ...props }, ref) => {
    return (
      <Dialog
        {...props}
        ref={ref}
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
        fullWidth
        sx={{ '& .MuiDialog-paper': { borderRadius: '20px', height: '88vh', width: '63%' } }}
      >
        <DialogTitle
          sx={{
            padding: '10px 24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid rgba(0,0,0,0.1)',
          }}
        >
          <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>{title}</Typography>
          <IconButton aria-label="Close" onClick={onClose} sx={{ color: 'black' }}>
            <CloseSharp />
          </IconButton>
        </DialogTitle>
        {children}
      </Dialog>
    );
  }
);

export default ModalContainer;
