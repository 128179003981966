import { Box, List } from '@mui/material';
import SidebarButton from './SidebarButton';
import { AttachMoneyOutlined, SettingsOutlined, ShowChartOutlined, VideoLibraryOutlined } from '@mui/icons-material';
import ProfileAvatar from '../ProfileAvatar';
import SignOutButton from '../buttons/SignOutButton';
// import LanguageButton from '../buttons/LanguageButton';

const Sidebar = () => {
  return (
    <Box
      sx={{
        width: '90px',
        height: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRight: '1.5px solid rgba(0, 0, 0, 0.10)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: '25px',
        }}
      >
        <ProfileAvatar />
        <Box sx={{ height: '10px' }} />
        <List>
          <SidebarButton icon={<VideoLibraryOutlined />} title={'Videos'} route={'/videos'} />
          <SidebarButton icon={<AttachMoneyOutlined />} title={'Earnings'} route={'/earnings'} />
          <SidebarButton icon={<ShowChartOutlined />} title={'Analytics'} route={'/analytics'} />
          <SidebarButton icon={<SettingsOutlined />} title={'Settings'} route={'/settings'} />
        </List>
      </Box>
      <Box sx={{ pb: '10px' }}>
        {/* <LanguageButton /> */}
        <SignOutButton />
      </Box>
    </Box>
  );
};

export default Sidebar;
