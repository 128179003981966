import { Box, Divider, InputAdornment, Pagination, TextField, Tooltip, Typography } from '@mui/material';
import Title from '../components/Title';
import { useVideos } from '../hooks';
import { Video, VideoStatus } from '../models';
import { useVideo } from '../stores';
import VideoItem from '../components/VideoItem';
import { isAfter } from 'date-fns';
import { useMemo, useState } from 'react';
import { Colors } from '../styles';
import NoVideos from '../assets/no_videos.png';
import { ArrowDownward, ArrowUpward, InfoOutlined, SearchOutlined } from '@mui/icons-material';
import UploadButton from '../components/buttons/UploadButton';

const tooltip = `Draft: This video is saved as a draft; submit it for review to enable monetization.

In Review: This video is currently under review. It will be published once approved.

Private: This video is only visible to you.

Public: This video is live and visible to everyone.

Rejected: This video has been rejected. Please check the guidelines or contact help center.
`;

export default function VideosPage() {
  const { data: videos = [] } = useVideos();
  const { handleModal } = useVideo();
  const [filter, setFilter] = useState<{
    search: string;
    statuses: VideoStatus[];
    date: 'ASC' | 'DESC';
  }>({
    search: '',
    statuses: [],
    date: 'DESC',
  });
  const [page, setPage] = useState<number>(1);
  const limit = 6;

  const total = useMemo(() => {
    let items = videos.filter((video) => video.status !== 'DELETED');

    if (filter.search !== '') {
      items = items.filter((video) => video.title.toLowerCase().includes(filter.search.toLowerCase()));
    }

    if (filter.statuses.length > 0) {
      items = items.filter((video) => filter.statuses.includes(video.status));
    }

    if (filter.date === 'DESC') {
      items = items.sort((a, b) => (isAfter(a.createdAt, b.createdAt) ? -1 : 1));
    } else {
      items = items.sort((a, b) => (isAfter(a.createdAt, b.createdAt) ? 1 : -1));
    }

    return items;
  }, [videos, filter]);

  const targets = useMemo(() => {
    const from = (page - 1) * limit;
    let to = page * limit;
    if (total.length < to) {
      to = total.length;
    }

    return Array.from(total).slice(from, to);
  }, [total, page]);

  function handleClickVideo(video: Video) {
    handleModal('EDIT', video);
  }

  function handleChangeSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setFilter((prev) => {
      return { ...prev, search: e.target.value };
    });
  }

  function handleChangeDateOrder() {
    setFilter((prev) => {
      return { ...prev, date: filter.date === 'ASC' ? 'DESC' : 'ASC' };
    });
  }

  const handlePage = (e: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box>
      <Title
        title={'Videos'}
        trailing={
          <TextField
            variant="outlined"
            margin="none"
            id="search"
            name="search"
            placeholder="Title"
            onChange={handleChangeSearch}
            rows={1}
            sx={{
              py: 0,
              width: '300px',
              '& .MuiInputBase-input': {
                py: '8px',
                pr: '10px',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        }
      />
      <Divider />
      <Box sx={{ display: 'flex', alignItems: 'center', pl: '32px', fontWeight: 600, height: '47px' }}>
        <Box sx={{ flex: 2, minWidth: '382px' }}>Video</Box>
        <Tooltip
          title={<Typography sx={{ fontSize: '14px', whiteSpace: 'pre-line' }}>{tooltip}</Typography>}
          arrow
          placement="left-start"
          enterTouchDelay={100}
          leaveTouchDelay={2000}
        >
          <Box sx={{ display: 'flex', flex: 0.75, minWidth: '100px', alignItems: 'center', justifyContent: 'start', gap: '4px' }}>
            Status
            <InfoOutlined sx={{ fontSize: '16px', color: 'black' }} />
          </Box>
        </Tooltip>
        <Box sx={{ flex: 1, minWidth: '160px', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleChangeDateOrder}>
          Date
          {filter.date === 'DESC' && <ArrowDownward sx={{ ml: '5px', fontSize: '16px', color: 'black' }} />}
          {filter.date === 'ASC' && <ArrowUpward sx={{ ml: '5px', fontSize: '16px', color: 'black' }} />}
        </Box>
        <Box sx={{ flex: 0.5, minWidth: '70px' }}>Views</Box>
        <Box sx={{ flex: 0.5, minWidth: '70px' }}>Likes</Box>
        <Box sx={{ flex: 0.3, minWidth: '50px' }}></Box>
      </Box>
      <Divider />
      {total.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end',
            height: '440px',
          }}
        >
          <img src={NoVideos} alt="No Videos" style={{ width: 300, minWidth: 300 }} />
          <Typography sx={{ color: 'grey', fontSize: '16px', mb: '12px' }}>Upload your first creation!</Typography>
          <UploadButton />
        </Box>
      )}
      {total.length > 0 && (
        <>
          <Box sx={{ maxHeight: '510px', overflow: 'scroll' }}>
            {targets.map((video) => {
              return (
                <Box
                  key={video.id}
                  onClick={() => handleClickVideo(video)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: Colors.lightGrey,
                    },
                  }}
                >
                  <VideoItem video={video} />
                  <Divider />
                </Box>
              );
            })}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: '32px', height: '47px' }}>
            <Typography sx={{ fontWeight: 500 }}>Total: {total.length}</Typography>
            <Pagination
              count={Math.ceil(total.length / limit)}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              shape="rounded"
              size="small"
              onChange={handlePage}
            />
          </Box>
          <Divider />
        </>
      )}
    </Box>
  );
}
