import { Box, CircularProgress, Typography } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { useAnalyticsRegions } from '../../hooks';
import { CountryCode } from '../../utils';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const SAMPLE = [
  { key: 'USA', value: 28 },
  { key: 'Japan', value: 24 },
  { key: 'Mexico', value: 16 },
  { key: 'UK', value: 12.5 },
  { key: 'Germany', value: 9.5 },
  { key: 'etc', value: 8 },
];

const RegionChart = () => {
  const [data, setData] = useState<{ key: string; value: number }[] | null>(null);
  const { data: regions } = useAnalyticsRegions();

  useEffect(() => {
    if (regions !== undefined) {
      setData(regions);
    }

    return () => {};
  }, [regions]);

  /**
   * TODO Convert ISO Country Codes to real name
   * https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
   */
  const targets = useMemo(() => {
    if (data === null) {
      return [];
    }

    const total = (data.length > 0 ? data : SAMPLE).reduce((prev, cur) => {
      return prev + cur.value;
    }, 0);

    return (data.length > 0 ? data : SAMPLE).map((item) => {
      return {
        key: CountryCode[item.key] ?? item.key,
        value: Number(((item.value / total) * 100).toFixed(1)),
      };
    });
  }, [data]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        border: '1px solid #ddd',
        borderRadius: '8px',
        width: '400px',
        p: '16px',
      }}
    >
      <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '0px' }}>Region</Typography>
      <Box sx={{ position: 'relative' }}>
        {!data && (
          <Box sx={{ width: '400px', height: '220px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={32} thickness={3} />
          </Box>
        )}
        {data && (
          <PieChart width={400} height={240} style={{ backgroundColor: 'white', margin: 'auto' }}>
            <Pie
              data={targets}
              cx="50%"
              cy="50%"
              labelLine={true}
              label={({ key, value }) => `${key}: ${value}%`}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              nameKey="key"
              startAngle={90}
              endAngle={-270}
            >
              {targets.map((entry, index) => (
                <Cell key={entry.key} fill={COLORS[index % COLORS.length]} style={{ outline: 'none' }} />
              ))}
            </Pie>
            <Tooltip formatter={(value) => `${value}%`} />
          </PieChart>
        )}
        {data && data.length === 0 && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '400px',
              height: '240px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.85)',
            }}
          >
            <Typography sx={{ color: 'grey', fontSize: '18px' }}>No data</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RegionChart;
