import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { Video } from '../../../models';
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import UploadFileCard from './cards/UploadFileCard';
import ProgressFileCard from './cards/ProgressFileCard';
import axios from 'axios';
import ImageCard from './cards/ImageCard';
import { AddPhotoAlternateOutlined, DeleteOutline, MoreVert } from '@mui/icons-material';
import CropperModal from '../CropperModal';

interface ThumbnailSectionProps {
  video: Video;
  [key: string]: any;
}

const ThumbnailSection: FC<ThumbnailSectionProps> = ({ video, ...props }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [customThumbnailpath, setCustomThumbnailPath] = useState<string | null>(null);
  const [isProgress, setIsProgress] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [fileToCrop, setFileToCrop] = useState<string | null>(null);

  useEffect(() => {
    setCustomThumbnailPath(video.customThumbnailPath);
  }, [video.customThumbnailPath]);

  function handleMenuClick(e: React.MouseEvent<HTMLElement>) {
    setAnchor(e.currentTarget);
  }

  function handleMenuClose(action?: 'change' | 'delete') {
    if (action === 'change') {
      if (ref.current === null) {
        return;
      }

      ref.current.click();
    }

    if (action === 'delete') {
      handleDeleteThumbnail();
    }

    setAnchor(null);
  }

  async function handleSelectThumbnail(e: ChangeEvent<HTMLInputElement>) {
    const files = e.target.files;
    if (files === null || files.length === 0) {
      return;
    }

    const file = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFileToCrop(reader.result as string);
    };

    if (ref.current !== null) {
      ref.current.value = '';
    }
  }

  async function handleCropThumbnail(file: File) {
    try {
      setIsProgress(true);

      const { upload, url } = await video.uploadCustomThumbnail(file.type, 'jpeg');

      await axios.put(upload, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      setCustomThumbnailPath(url);
    } catch (e) {
      console.error(e);
    } finally {
      setIsProgress(false);
    }
  }

  async function handleDeleteThumbnail() {
    try {
      if (customThumbnailpath === null) {
        return;
      }

      await video.deleteCustomThumbnail();
      setCustomThumbnailPath(null);
    } catch (e) {
      console.error(e);
    }
  }

  let customThumbnail = <UploadFileCard onChange={handleSelectThumbnail} accept="image/png,image/jpeg" />;

  if (isProgress) {
    customThumbnail = <ProgressFileCard />;
  } else if (customThumbnailpath !== null) {
    customThumbnail = (
      <Box
        sx={{
          position: 'relative',
          width: '194px', // Consider border width of children
          height: '110px', // Consider border width of children
          '&:hover .menuButton': {
            opacity: 1,
          },
        }}
      >
        <ImageCard src={customThumbnailpath} />
        <IconButton
          className="menuButton"
          onClick={handleMenuClick}
          sx={{
            position: 'absolute',
            top: 2,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: 'white',
            opacity: 0,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            },
          }}
        >
          <MoreVert sx={{ fontSize: '20px' }} />
        </IconButton>
        <Menu
          anchorEl={anchor}
          open={anchor !== null}
          onClose={() => handleMenuClose()}
          slotProps={{
            paper: {
              style: {
                borderRadius: '8px',
                boxShadow: '0 3px 5px rgba(0,0,0,0.2)',
              },
            },
          }}
        >
          <MenuItem onClick={() => handleMenuClose('change')}>
            <AddPhotoAlternateOutlined sx={{ mr: 1 }} />
            Change
          </MenuItem>
          <MenuItem onClick={() => handleMenuClose('delete')}>
            <DeleteOutline sx={{ mr: 1 }} />
            Delete
          </MenuItem>
        </Menu>
        <input ref={ref} type="file" accept={'image/png,image/jpeg'} hidden onChange={handleSelectThumbnail} />
      </Box>
    );
  }

  let thumbnail = <ProgressFileCard />;

  if (video.thumbnailPath !== null) {
    thumbnail = <ImageCard src={video.thumbnailPath} />;
  }

  return (
    <>
      <Box {...props}>
        <Typography component="div" sx={{ color: 'black', fontSize: '15px', fontWeight: 600 }}>
          Thumbnail
        </Typography>
        <Typography sx={{ color: 'grey', fontSize: '12px', mt: '2px' }}>Set a thumbnail that stands out and draws viewers' attention.</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            gap: '20px',
            mt: '12px',
          }}
        >
          {customThumbnail}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '3px',
            }}
          >
            {thumbnail}
            <Typography
              sx={{
                color: 'black',
                fontSize: '10px',
                textAlign: 'end',
              }}
            >
              Auto-generated
            </Typography>
          </Box>
        </Box>
      </Box>
      {fileToCrop && (
        <CropperModal
          open={fileToCrop !== null}
          src={fileToCrop}
          type={'thumbnail'}
          onClose={() => {
            setFileToCrop(null);
          }}
          onCrop={handleCropThumbnail}
        />
      )}
    </>
  );
};

export default ThumbnailSection;
