import { IconButton } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

export default function HepButton() {
  function handleClick() {
    window.open('https://faq.smthflex.com', '_blank');
  }

  return (
    <IconButton aria-label="Help" onClick={handleClick}>
      <HelpOutline sx={{ color: 'black' }} />
    </IconButton>
  );
}
