import { AppBar, Box, Button, IconButton, Typography } from '@mui/material';
import Logo512 from '../assets/logo_512.png';
import { useNavigate } from 'react-router-dom';
import UploadButton from './buttons/UploadButton';
import HelpButton from './buttons/HelpButton';
import { Colors } from '../styles';
import { LaunchOutlined } from '@mui/icons-material';
import env from '../env';
import { useAuth } from '../stores';

const Header = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  function handleHomeButton() {
    navigate('/');
  }

  function handleView() {
    let url = 'https://platform-dev.somethingflex.com';
    if (env.REACT_APP_STAGE === 'production') {
      url = 'https://www.smthflex.com';
    }

    window.open(`${url}/@${user?.nickname}`, '_blank');
  }

  return (
    <AppBar
      position="static"
      sx={{
        width: '100%',
        minWidth: '960px',
        height: '64px',
        backgroundColor: 'white',
        borderBottom: '1.5px solid rgba(0, 0, 0, 0.10)',
        boxShadow: 0, // 하단 음영 제거
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        pl: '12px',
        pr: '20px',
      }}
    >
      <IconButton
        edge="start"
        color="inherit"
        aria-label="logo"
        onClick={handleHomeButton}
        sx={{
          ':hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <img src={Logo512} alt="Logo" style={{ width: 140 }} />
      </IconButton>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <Button
          disableRipple
          onClick={handleView}
          sx={{ textTransform: 'none', padding: 0, '&:hover': { color: Colors.secondary, backgroundColor: 'white' } }}
        >
          <LaunchOutlined sx={{ fontSize: '15px', marginRight: '5px' }} />
          <Typography sx={{ fontSize: '14px', color: Colors.secondary }}>View on SmthFlex</Typography>
        </Button>
        <HelpButton />
        <UploadButton />
      </Box>
    </AppBar>
  );
};

export default Header;
